<div class="project-info-page flexbox direction-column">
  <eule-general-title [title]="'Projektinfo'">
    @if ((isLoading$ | async) === false) {
    <div class="flexbox align-center small-gap">
      @if (projectForm.invalid) {
      <div class="flexbox align-center small-gap">
        <mat-icon class="info" [matTooltip]="'Dieses Formular enthält ungültige Eingaben'">info</mat-icon>
      </div>
      }
      <div
        [euleTooltip]="
          _permissionService.hasRights('project_export_data')
            ? null
            : 'Sie besitzen nicht die benötigten Rechte, um diese Projektinformation zu exportieren.'
        "
        [noWrap]="true"
        [tooltipPositionOffsetY]="6"
      >
        <button
          mat-raised-button
          color="accent"
          class="no-shadow"
          [disabled]="!_permissionService.hasRights('project_export_data') || (isExportLoading$ | async)"
          (click)="exportProjectInfo()"
          data-cy="button-project-info-page-save"
        >
          <mat-icon>download</mat-icon>
          @if((isExportLoading$ | async)){<mat-spinner [color]="'primary'" diameter="20"></mat-spinner> }@else { Export }
        </button>
      </div>
      <button
        mat-raised-button
        color="primary"
        class="no-shadow"
        [disabled]="projectForm.invalid || !projectForm.dirty"
        (click)="update()"
        data-cy="button-project-info-page-save"
      >
        <mat-icon>save</mat-icon>
        Speichern
      </button>
    </div>
    }
  </eule-general-title>

  @if (isLoading$ | async) {
  <mat-spinner class="center"></mat-spinner>
  } @else {
  <mat-card class="custom-card w-100-percent">
    <mat-card-content>
      <!-- @if (!(isProjectLoading$ | async)) { -->
      <div [formGroup]="projectForm" class="container">
        <mat-tab-group dynamicHeight [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChange($event)" class="w-100-percent">
          <!-- TAB Allgemein -->
          <mat-tab data-cy="tab-project-info-page-general">
            <ng-template mat-tab-label>
              <ng-container
                [ngTemplateOutlet]="tabWarnIndicator"
                [ngTemplateOutletContext]="{ $implicit: { name: 'Allgemein', index: 0, formGroup: generalFormGroup } }"
              />
            </ng-template>
            <eule-tab-general [generalFormGroup]="generalFormGroup" [projectImage$]="projectImage$"></eule-tab-general>
          </mat-tab>

          <!-- Tab Baubeschreibung -->
          <mat-tab label="Baubeschreibung" data-cy="tab-project-info-page-buildingDescription">
            <ng-template mat-tab-label>
              <ng-container
                [ngTemplateOutlet]="tabWarnIndicator"
                [ngTemplateOutletContext]="{
                  $implicit: { name: 'Baubeschreibung', index: 1, formGroup: totalBuildingDescriptionFormGroup }
                }"
              />
            </ng-template>
            <eule-tab-building-description
              [totalBuildingDescriptionFormGroup]="totalBuildingDescriptionFormGroup"
            ></eule-tab-building-description>
          </mat-tab>

          <!-- Tab Nutzungsprofile -->
          <mat-tab label="Nutzungsprofile" data-cy="tab-project-info-page-usageProfile">
            <ng-template mat-tab-label>
              <ng-container
                [ngTemplateOutlet]="tabWarnIndicator"
                [ngTemplateOutletContext]="{ $implicit: { name: 'Nutzungsprofile', index: 2, formGroup: usageProfilesFormArray } }"
              />
            </ng-template>
            <eule-tab-usage-profiles [usageProfilesFormArray]="usageProfilesFormArray"></eule-tab-usage-profiles>
          </mat-tab>

          <!-- Tab Leistungsphasen -->
          <mat-tab label="Leistungsphasen" data-cy="tab-project-info-page-leistungsphasen">
            <ng-template mat-tab-label>
              <ng-container
                [ngTemplateOutlet]="tabWarnIndicator"
                [ngTemplateOutletContext]="{ $implicit: { name: 'Leistungsphasen', index: 3, formGroup: leistungsPhasenFormGroup } }"
              />
            </ng-template>
            <eule-tab-leistungsphasen [leistungsPhasenFormGroup]="leistungsPhasenFormGroup"></eule-tab-leistungsphasen>
          </mat-tab>

          <!-- Tab Zertifizierung -->
          <mat-tab label="Zertifizierung" data-cy="tab-project-info-page-certification">
            <ng-template mat-tab-label>
              <ng-container
                [ngTemplateOutlet]="tabWarnIndicator"
                [ngTemplateOutletContext]="{
                  $implicit: { name: 'Zertifizierung', index: 4, formGroup: totalCertificationDescriptionFormGroup }
                }"
              />
            </ng-template>
            <eule-tab-certification
              [certificationFormGroup]="certificationFormGroup"
              [totalCertificationDescriptionFormGroup]="totalCertificationDescriptionFormGroup"
            ></eule-tab-certification>
          </mat-tab>

          <mat-tab label="Gebäudeparameter" data-cy="tab-project-info-page-buildingParameters">
            <ng-template mat-tab-label>
              <ng-container
                [ngTemplateOutlet]="tabWarnIndicator"
                [ngTemplateOutletContext]="{ $implicit: { name: 'Gebäudeparameter', index: 5, formGroup: buildingParametersFormGroup } }"
              />
            </ng-template>
            <eule-tab-building-parameters [buildingParametersFormGroup]="buildingParametersFormGroup"></eule-tab-building-parameters>
          </mat-tab>

          <!-- Tab Ansprechpartner -->
          <!-- <mat-tab label="Ansprechpartner" data-cy="tab-project-info-page-contact">
              <ng-template mat-tab-label>
                <ng-container
                  [ngTemplateOutlet]="tabWarnIndicator"
                  [ngTemplateOutletContext]="{ $implicit: { name: 'Ansprechpartner', index: 6, formGroup: contactPersonsFormGroup } }"
                />
              </ng-template>
              <eule-tab-contact-persons [contactPersonsFormGroup]="contactPersonsFormGroup"></eule-tab-contact-persons>
            </mat-tab> -->

          <!-- Tab Fotos -->
          <mat-tab label="Fotos" data-cy="tab-project-info-page-images">
            <ng-template mat-tab-label>
              <ng-container
                [ngTemplateOutlet]="tabWarnIndicator"
                [ngTemplateOutletContext]="{ $implicit: { name: 'Fotos', index: 7, formGroup: totalImagesDescriptionFormGroup } }"
              />
            </ng-template>
            <eule-tab-images [totalImagesDescriptionFormGroup]="totalImagesDescriptionFormGroup"></eule-tab-images>
          </mat-tab>
        </mat-tab-group>
      </div>
      <!-- } -->
    </mat-card-content>
  </mat-card>
  }
</div>

<ng-template #tabWarnIndicator let-data>
  <div class="flexbox align-center small-gap">
    {{ data['name'] }}
    @if (data['formGroup'].invalid) {
    <mat-icon class="info" [matTooltip]="'Dieser Tab enthält ungültige Eingaben'"> info</mat-icon>
    }
  </div>
</ng-template>
