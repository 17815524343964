import { ResolveFn } from '@angular/router';
import { ProjectService } from '../services/project.service';
import { inject } from '@angular/core';
import { AuditService } from '../services/audit.service';
import { BonusService } from '../services/bonus/bonus.service';
import { UsageProfileService } from '../services/usage-profiles/usage-profile.service';
import { combineLatest, map, Observable, tap } from 'rxjs';
import { Bonus, UsageProfile, MixedUsageProfile } from '@eeule/eeule-shared';
import { FirebaseDocumentData } from '../../types/firebase-types';

/**
 * Resolver function to set audit dependencies.
 *
 * This function injects various services and performs the following tasks:
 * 1. Connects to the project observable and generates a mixed usage profile map if usage profiles are present.
 * 2. Fetches bonuses and sets them in the audit service, showing an error message if no bonuses are found.
 * 3. Fetches usage profiles and sets them in the audit service, showing an error message if no usage profiles are found.
 *
 */
export const setAuditDependenciesResolver: ResolveFn<[
    Map<string, Partial<MixedUsageProfile>> | null,
    FirebaseDocumentData<Bonus>[] | null,
    FirebaseDocumentData<UsageProfile>[] | null
]> = (): Observable<[
    Map<string, Partial<MixedUsageProfile>> | null,
    FirebaseDocumentData<Bonus>[] | null,
    FirebaseDocumentData<UsageProfile>[] | null
]> => {
  const _projectService: ProjectService = inject(ProjectService);
  const _auditService: AuditService = inject(AuditService);
  const _usageProfileService: UsageProfileService = inject(UsageProfileService);
  const _bonusService: BonusService = inject(BonusService);

  // Connects to the project observable and generates a mixed usage profile map if usage profiles are present.
  const usageProfilesMap$: Observable<Map<string, Partial<MixedUsageProfile>> | null> = _projectService.project$.pipe(
    map(project => {
      if (!project?.usageProfiles) return null;
      return _auditService.generateAndRetrieveProjectMixedUsageProfileMap(project.usageProfiles);
    }),

    tap(usageProfilesMap => {
      if (usageProfilesMap) _auditService.setProjectMixedUsageProfileMap(usageProfilesMap);
    }));

  // Fetches bonuses and sets them in the audit service, showing an error message if no bonuses are found.
  const bonuses$: Observable<FirebaseDocumentData<Bonus>[] | null>
    = _bonusService.getBonuses(null, null, true).pipe(
    map(bonusesRes => {
      return bonusesRes?.data || null;
    }),
    tap(bonuses => {
      if (bonuses) {
        _auditService.setAllBonuses(bonuses as Bonus[]);
      }
    }));

  // Fetches usage profiles and sets them in the audit service, showing an error message if no usage profiles are found.
  const usageProfiles$: Observable<FirebaseDocumentData<UsageProfile>[] | null>
    = _usageProfileService.getUsageProfiles(null, null, true).pipe(
    map(upRes => upRes?.data || null),
    tap(usageProfiles => {
      if (usageProfiles) {
        _auditService.setAllUsageProfiles(usageProfiles);
      }
    }));

  return combineLatest([usageProfilesMap$, bonuses$, usageProfiles$]);
};
