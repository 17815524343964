import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { LeafIconData, LeafIconListComponent } from '../leaf-icon-list/leaf-icon-list.component';

export type LabelValueField = {
  label: string;
  value: string | LeafIconData[];
  isLeafIconArray?: boolean; // If true, the value is an array of strings, and each item will be styled as leaf icon
}

@Component({
  selector: 'eule-label-value-card',
  standalone: true,
  imports: [
    MatCard,
    MatCardContent,
    MatCardHeader,
    MatCardTitle,
    LeafIconListComponent,
  ],
  templateUrl: './label-value-card.component.html',
  styleUrl: './label-value-card.component.scss',
})
export class LabelValueCardComponent implements OnChanges {
  /** Fields to display */
  @Input({ required: true }) fields!: LabelValueField[];

  /** Number of columns to display */
  @Input() columnCount: number = 4;

  /** Title of the card */
  @Input() title?: string;

  /** Fixed width of the columns */
  @Input() columnWidth?: string;

  /**
   * Rows of fields to display, each row containing a subset of LabelValueField items.
   * This is generated based on the number of columns specified.
   */
  fieldRows?: Array<LabelValueField[]>;

  /**
   * Lifecycle hook that is called when any data-bound property of a directive changes.
   *
   * @param {SimpleChanges} changes - The changes that have occurred.
   */
  ngOnChanges(changes: SimpleChanges): void {
    const fieldChanges = changes['fields'];
    const columnCountChanges = changes['columnCount'];
    if (fieldChanges || columnCountChanges) {
      this.fieldRows = this.generateFieldRows();
    }
  }

  /**
   * Converts a value to an array of LeafIconData.
   *
   * This function checks if the provided value is a string or an array of LeafIconData.
   * If the value is a string, it logs an error and returns an array with a single LeafIconData object.
   * If the value is already an array of LeafIconData, it returns the value as is.
   *
   * @param {string | LeafIconData[]} value - The value to convert.
   * @returns {LeafIconData[]} An array of LeafIconData.
   */
  getValueAsLeafIconData(value: string | LeafIconData[]): LeafIconData[] {
    if (typeof value === 'string') {
      console.error('expected LeafIconData[] but got string');
      return [{ value, tooltip: '' }];
    }
    return value;
  }

  /**
   * Generates rows of fields based on the specified column count.
   *
   * @returns {Array<LabelValueField[]>} An array of rows, each containing a subset of LabelValueField items.
   */
  private generateFieldRows(): Array<LabelValueField[]> {
    const rows: Array<LabelValueField[]> = [];
    for (let i = 0; i < this.fields.length; i += this.columnCount) {
      rows.push(this.fields.slice(i, i + this.columnCount));
    }
    return rows;
  }

}
