import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { Pagination, ProjectData } from '@eeule/eeule-shared';
import { SearchBarComponent } from '../../../core/components/search-bar/search-bar.component';
import { UserService } from '../../../core/services/user.service';
import { ProjectPlansDialogComponent } from '../project-plans-dialog/project-plans-dialog.component';

const NAMES: string[] = [
  'Maia',
  'Asher',
  'Olivia',
  'Atticus',
  'Amelia',
  'Jack',
  'Charlotte',
  'Theodore',
  'Isla',
  'Oliver',
  'Isabella',
  'Jasper',
  'Cora',
  'Levi',
  'Violet',
  'Arthur',
  'Mia',
  'Thomas',
  'Elizabeth',
];

const STATUSES: string[] = ['Aktiv', 'In Planung', 'Fertig'];
const CATEGORIES: string[] = ['Wohnen', 'Büro', 'Schule'];
const TITLES: string[] = [
  'Neubau Mehrfamilienhaus',
  'Sanierung Bürogebäude',
  'Aufstockung Holzbau',
  'Neubau Verwaltungsgebäude',
  'Erweiterung Schule',
  'Neubau Holzmodul',
  'Sanierung Studentenwohnheim',
];
const LOCATIONS: string[] = ['Stuttgart', 'München', 'Berlin', 'Hamburg', 'Bremen', 'Köln', 'Düsseldorf', 'Essen', 'Dresden', 'Frankfurt'];

/**
 * TODO: Consume the project folders "project-list" component here instead of defining a new one
 *
 * @export
 * @class ProjectListComponent
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'eule-project-list',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule, MatPaginatorModule, SearchBarComponent],
  templateUrl: './project-list.component.html',
  styleUrl: './project-list.component.scss',
})
export class ProjectListComponent implements AfterViewInit {
  displayedColumns: string[] = [
    'id',
    'title',
    'status',
    'responsible',
    'date',
    'location',
    'category',
    'preCheck',
    'preCertification',
    'certification',
    'QNG',
    'euTaxonomy',
  ];
  dataSource: MatTableDataSource<ProjectData>;

  @ViewChild(MatPaginator) paginator!: MatPaginator | null;
  @ViewChild(MatSort) sort!: MatSort | null;
  public filterValue: string = '';

  constructor(public userService: UserService, private _router: Router, private _projectPlansDialog: MatDialog) {
    // Create 100 users
    const users = Array.from({ length: 100 }, (_, k) => createNewUser(k + 1));

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(users);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(_filterValue: string) {
    this.filterValue = _filterValue;
    this.dataSource.filter = _filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public changeProjectPlan(project: ProjectData): void {
    const dialogRef = this._projectPlansDialog.open<ProjectPlansDialogComponent, { projectId: string }, boolean>(ProjectPlansDialogComponent, {
      width: '90vw',
      maxWidth: '90vw',
      height: '90vh',
      data: { projectId: project.id },
    });

    dialogRef.afterClosed().subscribe((result: boolean | undefined) => {
      console.info(result);
    });
  }

  public onChangePageSize(pageSize: Pagination | number) {
    this.userService.updateUser(this.userService.euleUser$.value!.id, { pagination: pageSize as Pagination });
  }
}

/** Builds and returns a new User. */
function createNewUser(id: number): ProjectData {
  const name = NAMES[Math.round(Math.random() * (NAMES.length - 1))] + ' ' + NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) + '.';

  return {
    id: id.toString(),
    title: TITLES[Math.round(Math.random() * (TITLES.length - 1))],
    status: STATUSES[Math.round(Math.random() * (STATUSES.length - 1))],
    responsible: name,
    date: '31.12.2023',
    location: LOCATIONS[Math.round(Math.random() * (LOCATIONS.length - 1))],
    category: CATEGORIES[Math.round(Math.random() * (CATEGORIES.length - 1))],
    preCheck: Math.round(Math.random() * 100),
    preCertification: Math.round(Math.random() * 100),
    certification: Math.round(Math.random() * 100),
    QNG: Math.round(Math.random() * 100),
    euTaxonomy: Math.round(Math.random() * 100),
  };
}
