<div class="leaf-list flexbox xs-gap align-end">
  @for (leaf of leafIcons; track leaf; let index = $index) {
    @if (index < visibleLeafs || visibleLeafs === 0) {
      <div class="leaf custom-icon accent-bg leaf-bg"
           tooltipPosition="bottom"
           [euleTooltip]="leaf.tooltip">
        {{ leaf.value }}
      </div>
    }
    @if (index === visibleLeafs && visibleLeafs !== 0) {
      <div class="show-more accent strong cursor-pointer"
           tooltipPosition="top"
           [tooltipPositionOffsetY]="5"
           [euleTooltip]="allItemsTooltip"
           [htmlTooltipContent]="true"
      >
        ...
      </div>
    }
  }
</div>
