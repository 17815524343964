import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { DocumentData, Pagination, Project } from '@eeule/eeule-shared';
import { BehaviorSubject, forkJoin, from, iif, lastValueFrom, map, mergeMap, of, Subscription, switchMap } from 'rxjs';
import { handleBasicError } from '../../../../util/error.helper';
import {
  ConfirmDialogComponent,
  ConfirmDialogData as ConfirmDialogDataConfig,
} from '../../../core/components/confirm-dialog/confirm-dialog.component';
import { GeneralTitleComponent } from '../../../core/components/general-title/general-title.component';
import { SearchBarComponent } from '../../../core/components/search-bar/search-bar.component';
import { CustomTooltipDirective } from '../../../core/directives/custom-tooltip.directive';
import { TypeSafeMatCellDef } from '../../../core/directives/TypeSafeMatCellDef';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';
import { DocumentService } from '../../../core/services/document.service';
import { PermissionService } from '../../../core/services/permission.service';
import { ProjectService } from '../../../core/services/project.service';
import { StorageService } from '../../../core/services/storage.service';
import { ProjectUserDisplay, UserService } from '../../../core/services/user.service';
import { DocumentTypeEnum } from '../../../enums/DocumentType.enum';
import {
  UploadDocumentDialogComponent,
  UploadDocumentDialogConfig,
} from '../../components/upload-document-dialog/upload-document-dialog.component';

@Component({
  selector: 'eule-audit-documents-page',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    GeneralTitleComponent,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    SearchBarComponent,
    TypeSafeMatCellDef,
    CustomTooltipDirective,
  ],
  templateUrl: './audit-documents-page.component.html',
  styleUrl: './audit-documents-page.component.scss',
})
export class AuditDocumentsPageComponent implements AfterViewInit {
  public displayedColumns: string[] = [
    'name',
    'type',
    'version',
    'displayName',
    'createdDate',
    'lastUpdatedDate',
    'description',
    'options',
  ];
  public dataSource: MatTableDataSource<DocumentData> = new MatTableDataSource();
  public documents: DocumentData[] = [];
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public isInitialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public filterValue: string = '';
  public projectId: string | undefined;

  @ViewChild(MatPaginator) paginator!: MatPaginator | null;
  @ViewChild(MatSort) sort!: MatSort | null;

  constructor(
    public _permissionService: PermissionService,
    public userService: UserService,
    private _analyticsService: AnalyticsService,
    private _storageService: StorageService,
    private _projectService: ProjectService,
    private _documentService: DocumentService,
    private _router: Router,
    private _uploadDocumentDialog: MatDialog,
    private _confirmDeleteDialog: MatDialog
  ) {
    this._analyticsService.sendPageView('audit-documents-page');
    //fixme just a workaround, project should be emitted elsewhere at this point
    this.projectId = this._projectService.project$.value?.id;
    if (!this.projectId) {
      const urlProjectPartArr: string[] = this._router.url.split('/');
      const projectIndex: number = urlProjectPartArr.indexOf('project');
      this.projectId = urlProjectPartArr[projectIndex + 1] || '';
    }

    this._documentService
      .getLiveAllProjectDocumentsFromFirestore(this.projectId)
      .pipe(
        mergeMap((documents: DocumentData[]) => {
          // Prüfen, ob das Dokumenten-Array leer ist
          return iif(
            () => documents.length === 0,
            of([]),
            of(documents).pipe(
              mergeMap(docs => {
                const arr = docs.map(_document => {
                  if (_document.creatorId && this.projectId) {
                    return this._projectService.getProjectUserDisplay(this.projectId, _document.creatorId).pipe(
                      map((user: ProjectUserDisplay) => {
                        return { ..._document, displayName: `${user?.firstName || ''} ${user?.lastName || ''}` }; // only for display purposes
                      })
                    );
                  } else {
                    return of(_document);
                  }
                });
                if (!arr?.length) {
                  this.isLoading$.next(false);
                  this.isInitialized$.next(true);
                }
                return forkJoin(arr);
              })
            )
          );
        })
      )
      .subscribe((_documents: DocumentData[]) => {
        this.documents = _documents;
        this.dataSource.data = this.documents.map(doc => {
          const docType: DocumentTypeEnum = DocumentTypeEnum[doc.type as keyof typeof DocumentTypeEnum];
          return {
            ...doc,
            type: docType,
          };
        });
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading$.next(false);
        this.isInitialized$.next(true);
      });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(_filterValue: string) {
    this.filterValue = _filterValue;
    this.dataSource.filter = _filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async onAttachmentKeyDown(event: KeyboardEvent, id: string) {
    if (event.key === 'Enter' || event.key === ' ') {
      await this.onOpenAttachment(id);
      event.preventDefault();
    }
  }

  async onOpenAttachment(id: string) {
    const project: Project | null = this._projectService.project$.value;
    if (!project) {
      return handleBasicError('Error while retrieving current project');
    }
    const downloadUrl: string = await lastValueFrom(this._storageService.getProjectDocumentDownloadUrl(project.id, id));
    window.open(downloadUrl, '_blank');
  }

  public newDocument() {
    this._analyticsService.sendEvent('button_click', {
      label: 'audit-documents-page_new-document',
    });
    const dialogRef = this._uploadDocumentDialog.open<UploadDocumentDialogComponent, UploadDocumentDialogConfig, DocumentData>(
      UploadDocumentDialogComponent,
      {
        width: '600px',
        maxWidth: '70vw',
        data: null,
      }
    );

    dialogRef.afterClosed().subscribe((document: DocumentData | undefined) => {
      if (document && this.projectId) {
        this._storageService.uploadProjectDocument(this.projectId, document).subscribe();
      }
    });
  }

  public newDocumentVersion(document: DocumentData) {
    this._analyticsService.sendEvent('button_click', {
      label: 'audit-documents-page_new-document-version',
    });
    const dialogRef = this._uploadDocumentDialog.open<UploadDocumentDialogComponent, UploadDocumentDialogConfig, DocumentData>(
      UploadDocumentDialogComponent,
      {
        width: '600px',
        maxWidth: '70vw',
        data: document,
      }
    );

    dialogRef.afterClosed().subscribe((document: DocumentData | undefined) => {
      if (document && this.projectId) {
        this._storageService.uploadProjectDocument(this.projectId, document).subscribe();
      }
    });
  }

  public clickDocument() {}

  public onChangePageSize(pageSize: Pagination | number) {
    this.userService.updateUser(this.userService.euleUser$.value!.id, { pagination: pageSize as Pagination });
  }

  public downloadDocument(documentId: string, name: string) {
    this._analyticsService.sendEvent('button_click', {
      label: 'audit-documents-page_download-document',
    });
    this._storageService.getProjectDocumentDownloadUrl(this._projectService.project$.value!.id, documentId).subscribe((url: string) => {
      this._storageService.downloadFileByUrlToSystem(url, name);
    });
  }

  public async deleteDocument(id: string) {
    this._analyticsService.sendEvent('button_click', {
      label: 'audit-documents-page_delete-document',
    });
    const dialogData: ConfirmDialogDataConfig = {
      contentLangKey: 'dialogs.confirmDirty',
    };
    const res: Subscription = from(
      lastValueFrom(
        this._confirmDeleteDialog
          .open(ConfirmDialogComponent, {
            width: '360px',
            data: dialogData,
          })
          .afterClosed()
      )
    )
      .pipe(
        switchMap((confirmation: boolean) => {
          if (confirmation) {
            return this._documentService.deleteDoc(id);
          } else {
            return of(false);
          }
        })
      )
      .subscribe(() => res.unsubscribe());
  }

  /**
   * Checks if the current user can delete a document based on their permissions and the document's creator.
   *
   * @protected
   * @param {DocumentData} document - The document to check.
   * @returns {boolean} True if the user can delete the document, false otherwise.
   */
  protected canDeleteDocument(document: DocumentData): boolean {
    if (this._permissionService.hasRights('project_delete_documents')) {
      return true;
    }

    return (
      document.creatorId === this._projectService.projectUser$.value?.id &&
      this._permissionService.hasRights('project_delete_documents_own')
    );
  }

  /**
   * Checks if the current user can update a document based on their permissions and the document's creator.
   *
   * @protected
   * @param {DocumentData} document - The document to check.
   * @returns {boolean} True if the user can update the document, false otherwise.
   */
  protected canUpdateDocument(document: DocumentData): boolean {
    if (this._permissionService.hasRights('project_update_documents')) {
      return true;
    }

    return (
      document.creatorId === this._projectService.projectUser$.value?.id &&
      this._permissionService.hasRights('project_update_documents_own')
    );
  }

  /**
   * Checks if the current user can open a document based on their permissions and the document's creator.
   *
   * @protected
   * @param {DocumentData} document - The document to check.
   * @returns {boolean} True if the user can open the document, false otherwise.
   */
  protected canOpenDocument(document: DocumentData): boolean {
    if (this._permissionService.hasRights('project_read_documents')) {
      return true;
    }

    return (
      document.creatorId === this._projectService.projectUser$.value?.id && this._permissionService.hasRights('project_read_documents_own')
    );
  }
}
