import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DocumentData } from '@eeule/eeule-shared';
import { filter, map, Observable, Subscription, switchMap, take, takeUntil, tap } from 'rxjs';
import { BaseComponent } from '../../../../../core/components/base/base.component';
import {
  ConnectItemsDialogComponent,
  ConnectItemsDialogConfig,
} from '../../../../../core/components/connect-items-dialog/connect-items-dialog.component';
import { DocumentService } from '../../../../../core/services/document.service';
import { ProjectService } from '../../../../../core/services/project.service';
import { UsageProfileEnum } from '../../../../../enums/UsageProfile.enum';
import { DocumentTileComponent } from '../../../../components/document-tile/document-tile.component';
import {
  AttestationFormGroup,
  DescriptionFormGroup,
  DescriptionGroupFormGroup,
  ProjectDescriptionGroupAttachment,
} from '../../project-info-page/project-info-page.component';
import { ConnectAttachmentsService } from '../../services/connect-attachments.service';
import {
  CustomQuillEditorToolbarSetComponent
} from '../../../../../core/components/custom-quill-editor-toolbar-set/custom-quill-editor-toolbar-set.component';
import { QuillEditorComponent } from 'ngx-quill';

@Component({
  selector: 'eule-usage-profiles-group',
  standalone: true,
  imports: [
    CommonModule,
    DocumentTileComponent,
    MatButtonModule,
    MatCheckbox,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatTooltipModule,
    CustomQuillEditorToolbarSetComponent,
    QuillEditorComponent,
  ],
  providers: [ConnectAttachmentsService], // Services provided like this will be Indidual Services for each component and will NOT be Singletons!
  templateUrl: './usage-profiles-group.component.html',
  styleUrl: './usage-profiles-group.component.scss',
})
export class UsageProfileGroupComponent extends BaseComponent {
  @Input() usageProfileFormGroup!: FormGroup<DescriptionGroupFormGroup>;
  public usageProfileEnum: typeof UsageProfileEnum = UsageProfileEnum;

  public groupDocuments$: Observable<DocumentData[]> = this.projectService.project$.pipe(
    filter(proj => proj !== null), // Skip Projects that are null
    switchMap(project => this._documentService.getLiveAllProjectDocumentsFromFirestore(project!.id)),
    map(docs => docs.filter(doc => doc.projectInfoDescriptionGroupIds?.includes(this.id)))
  );
  public allDocuments$: Observable<DocumentData[]> = this.projectService.project$.pipe(
    filter(proj => proj !== null), // Skip Projects that are null
    switchMap(project => this._documentService.getLiveAllProjectDocumentsFromFirestore(project!.id))
  );

  public connectedGroupDocs: DocumentData[] = [];
  public allGroupDocsWithConnectedFlag: Partial<ProjectDescriptionGroupAttachment>[] = [];
  private connectAttachmentsDialogRef: MatDialogRef<ConnectItemsDialogComponent<Partial<ProjectDescriptionGroupAttachment>>> | null = null;

  get usageProfileType(): FormGroup<string | null> {
    return this.usageProfileFormGroup.get('usageProfile') as unknown as FormGroup<string | null>;
  }
  get descriptionGroup(): FormGroup<string | null> {
    return this.usageProfileFormGroup.get('descriptionGroup') as unknown as FormGroup<string | null>;
  }
  get id(): string {
    return this.descriptionGroup!.get('id')?.value || '';
  }
  get title(): string {
    return this.descriptionGroup?.get('title')?.value || '';
  }
  get attestations(): FormArray<FormGroup<AttestationFormGroup>> {
    return (this.descriptionGroup?.get('attestations') as FormArray<FormGroup<AttestationFormGroup>>) || new FormArray([]);
  }
  get descriptions(): FormArray<FormGroup<DescriptionFormGroup>> {
    return (this.descriptionGroup?.get('descriptions') as FormArray<FormGroup<DescriptionFormGroup>>) || new FormArray([]);
  }

  public constructor(
    public projectService: ProjectService,
    private _documentService: DocumentService,
    private _dialog: MatDialog,
    private _connectAttachmentsService: ConnectAttachmentsService
  ) {
    super();
    this._loadDocs();
  }

  public getAttestationGroupAt(index: number): FormGroup<AttestationFormGroup> {
    return this.attestations.at(index);
  }
  public getDescriptionGroupAt(index: number): FormGroup<DescriptionFormGroup> {
    return this.descriptions.at(index);
  }
  public getDescriptionTitleAt(index: number): string {
    return this.descriptions.at(index)!.get('title')?.value || '';
  }
  public getAttestationDescriptionAt(index: number): string {
    return this.attestations.at(index)!.get('description')?.value || '';
  }

  public onOpenAddAttachmentDialog() {
    // Open the ConnectItemsDialogComponent with the provided configuration
    this.connectAttachmentsDialogRef = this._dialog.open<
      ConnectItemsDialogComponent<Partial<ProjectDescriptionGroupAttachment>>,
      ConnectItemsDialogConfig<Partial<ProjectDescriptionGroupAttachment>>,
      Partial<ProjectDescriptionGroupAttachment>[] | null
    >(ConnectItemsDialogComponent, {
      data: {
        items: this.allGroupDocsWithConnectedFlag,
        itemNameKey: 'name',
        itemDescriptionKey: 'description',
        dialogTitle: 'Anhänge verknüpfen',
        listLabel: 'Vorhandene Projektdokumente',
        newElementButtonLabel: 'Hochladen',
      },
      width: '400px',
      minHeight: '400px',
    });

    // Subscribe to the afterClosed event to handle the dialog close action
    const afterClosedSub: Subscription = this.connectAttachmentsDialogRef.afterClosed().subscribe(connectedAttachments => {
      // console.log('connectedAttachments', connectedAttachments);
      if (!connectedAttachments) return;

      // UPDATING the new Set of currently connected documents
      this.allDocuments$
        .pipe(
          take(1),
          switchMap(allDocuemnts => this._connectAttachmentsService.onUpdate(allDocuemnts, connectedAttachments, this.id))
        )
        .subscribe();

      afterClosedSub.unsubscribe();
      openItemSub.unsubscribe();
      createItemSub.unsubscribe();
    });

    // Subscribe to the openItem event to handle opening an attachment
    const openItemSub: Subscription = this.connectAttachmentsDialogRef.componentInstance.openItem.subscribe(id => {
      this._connectAttachmentsService.onOpenAttachment(id).catch(error => {
        console.error(error);
      });
    });

    // Subscribe to the createItem event to handle creating a new attachment
    const createItemSub: Subscription = this.connectAttachmentsDialogRef.componentInstance.createItem.subscribe(() => {
      this._connectAttachmentsService.onNewProjectDescriptionGroupAttachment();
    });
  }

  private _loadDocs() {
    this.groupDocuments$
      .pipe(
        tap((docs: DocumentData[]) => (this.connectedGroupDocs = docs)),
        takeUntil(this.stop$)
      )
      .subscribe();

    this.allDocuments$.subscribe(docs => {
      this.allGroupDocsWithConnectedFlag = docs.map(doc => {
        if (this.connectedGroupDocs.some(o => o.id === doc.id)) {
          return { ...doc, connected: true };
        }
        return doc;
      });
      if (!this.connectAttachmentsDialogRef) {
        return;
      }
      this.connectAttachmentsDialogRef.componentInstance?.setItems<Partial<ProjectDescriptionGroupAttachment>>(this.allGroupDocsWithConnectedFlag);
    });
  }
}
