import { Component, effect, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { TaskData } from '@eeule/eeule-shared';
import { BaseComponent } from '../../../../core/components/base/base.component';
import { HighlightColors, ThemeService } from '../../../../core/services/theme.service';
import {
  DefaultPieChartData,
  DonutChartComponent,
} from '../../../../core/components/charts/donut-chart/donut-chart.component';
import { SnackbarService } from '../../../../core/services/snackbar.service';
import { TaskDiscipline, TaskPriority, TaskService, TaskType } from '../../../../core/services/task.service';
import { TaskTypeEnum } from '../../../../enums/TaskType.enum';
import { DisciplineEnum } from '../../../../enums/Discipline.enum';
import { DgnbIndicatorX } from '../../../../../util/indicator.helper';
import * as am5 from '@amcharts/amcharts5';
import { IndicatorStatusEnum } from '../../../../enums/IndicatorStatus.enum';
import { AmChartService } from '../../../../core/services/am-chart.service';
import { PriorityEnum } from '../../../../enums/Priority.enum';

@Component({
  selector: 'eule-audit-task-data-charts',
  standalone: true,
  imports: [
    DonutChartComponent,
  ],
  templateUrl: './audit-task-data-charts.component.html',
  styleUrl: './audit-task-data-charts.component.scss',
})
export class AuditTaskDataChartsComponent extends BaseComponent implements OnChanges {
  /** The task data */
  @Input({ required: true }) tasks!: TaskData[];

  /** The data for the discipline chart */
  disciplineChartData: DefaultPieChartData[] | null = null;

  /** The data for the type chart */
  typeChartData: DefaultPieChartData[] | null = null;

  /** The data for the status chart */
  statusChartData: DefaultPieChartData[] | null = null;

  /** The data for the priority chart */
  priorityChartData: DefaultPieChartData[] | null = null;

  /** The default height for the chart */
  defaultChartHeight: number = 200;

  /** The Y position offset for the donut chart */
  donutYPosition: number = -5;

  /** The number of visible legend items in the chart */
  numberOfVisibleLegendItems: number = 7;

  /** The Y position offset for the tooltip */
  tooltipPositionOffsetY: number = -50;

  constructor(
    private _themeService: ThemeService,
    private _snackbarService: SnackbarService,
    private _taskService: TaskService,
    private _amChartService: AmChartService,
  ) {
    super();

    effect(() => {
      if (this._themeService.themeSig()) {
        this.generateChartData();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const taskDataChange: SimpleChange = changes['taskData'];
    if (taskDataChange?.currentValue && !taskDataChange?.firstChange) {
      this.generateChartData();
    }
  }

  /**
   * Generates chart data for all task charts.
   *
   * This function checks if the tasks are defined. If not, it handles the error.
   * Otherwise, it generates data for discipline, type, status, and priority charts.
   */
  generateChartData() {
    if (!this.tasks) {
      this.handleNoTaskDataError();
      return;
    }
    this.generateTaskDisciplineChartData();
    this.generateTaskTypeChartData();
    this.generateTaskStatusChartData();
    this.generateTaskPriorityChartData();
  }

  /**
   * Generates data for the discipline chart.
   *
   * This function groups tasks by their discipline and maps the data to the format required by the chart.
   */
  generateTaskDisciplineChartData() {
    const disciplineTasksMap: Map<TaskDiscipline, TaskData[]> = this._taskService.generateDisciplineTasksMap(this.tasks);

    this.disciplineChartData = Array.from(
      disciplineTasksMap,
      ([discipline, tasks]) => ({discipline, taskCount: tasks.length})
    ).map(item => {
      return {
        category: DisciplineEnum[item.discipline],
        value: item.taskCount,
      }
    });
  }

  /**
   * Generates data for the type chart.
   *
   * This function groups tasks by their type and maps the data to the format required by the chart.
   */
  generateTaskTypeChartData() {
    const typeTasksMap: Map<TaskType, TaskData[]> = this._taskService.generateTypeTasksMap(this.tasks);

    this.typeChartData = Array.from(
      typeTasksMap,
      ([type, tasks]) => ({type, taskCount: tasks.length})
    ).map(item => {
      return {
        category: TaskTypeEnum[item.type],
        value: item.taskCount,
      }
    });
  }

  /**
   * Generates data for the status chart.
   *
   * This function groups tasks by their status and maps the data to the format required by the chart.
   * It also applies the appropriate colors and priorities to the chart items.
   */
  generateTaskStatusChartData() {
    const highlightColors: HighlightColors = this._themeService.themeHighlightColors;
    const statusTasksMap: Map<string, DgnbIndicatorX[]> = this._taskService.generateStatusTasksMap(this.tasks);

    this.statusChartData = Array.from(statusTasksMap, ([name, value]) => ({ name, value }))
      .map(item => {
        const colorString = this._amChartService.statusColorMap.get(item.name);
        const colorToApply = colorString
          ? am5.color(colorString)
          : am5.color(highlightColors.accent);
        return {
          category: IndicatorStatusEnum[item.name as keyof typeof IndicatorStatusEnum],
          value: item.value.length,
          color: colorToApply,
          priority: this._amChartService.statusPriorityMap.get(item.name) || 0,
        };
      });
  }

  /**
   * Generates data for the priority chart.
   *
   * This function groups tasks by their priority and maps the data to the format required by the chart.
   */
  generateTaskPriorityChartData() {
    const priorityTasksMap: Map<TaskPriority, TaskData[]> = this._taskService.generatePriorityTasksMap(this.tasks);

    this.priorityChartData = Array.from(
      priorityTasksMap,
      ([priority, tasks]) => ({priority, taskCount: tasks.length})
    ).map(item => {
      return {
        category: PriorityEnum[item.priority],
        value: item.taskCount,
      }
    });
  }

  /**
   * Handles the error when task data is not defined.
   *
   * This function shows an error message using the snackbar service and throws an error.
   */
  handleNoTaskDataError() {
    this._snackbarService.showErrorMessage('Beim Abrufen der Aufgaben ist ein Fehler aufgetreten.');
    throw new Error('could not generate task charts. Task data is not defined.');
  }
}
