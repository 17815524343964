import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { RouterLink } from '@angular/router';
import { AppConfigSignUpSignIn } from '@eeule/eeule-shared';
import { UserCredential, createUserWithEmailAndPassword, getAuth, sendEmailVerification } from 'firebase/auth';
import firebase from 'firebase/compat';
import { BehaviorSubject, delay, from, switchMap, take, takeUntil, tap } from 'rxjs';
import { handleBasicError } from '../../../util/error.helper';
import { passwordMisMatchValidator } from '../../../util/validation.helper';
import { BaseComponent } from '../../core/components/base/base.component';
import { AppConfigService } from '../../core/services/appConfig/app-config.service';
import { AuthService } from '../../core/services/auth-christian/auth.service';
import { SnackbarService } from '../../core/services/snackbar.service';
import { UserService } from '../../core/services/user.service';
import FirebaseError = firebase.FirebaseError;

type SignUpForm = {
  email: FormControl<string | null>;
  password: FormControl<string | null>;
  confirmPassword: FormControl<string | null>;
  noticeCheck: FormControl<boolean | null>;
  trackingCheck: FormControl<boolean | null>;
};

@Component({
  selector: 'eule-signup-page',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    RouterLink,
    MatProgressSpinnerModule,
  ],
  templateUrl: './signup-page.component.html',
  styleUrl: './signup-page.component.scss',
})
export class SignupPageComponent extends BaseComponent {
  signUpForm: FormGroup<SignUpForm> = this._formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&+\\-§€])[A-Za-z\\d$@$!%*?&+\\-§€]{8,}$')]],
    confirmPassword: ['', [Validators.required, passwordMisMatchValidator('password')]],
    noticeCheck: [false],
    trackingCheck: [false],
  });

  public signUpMode: boolean = true;
  private _userCredential: UserCredential | null = null;

  /** Error state matcher for form validation. */
  errorStateMatcher: ErrorStateMatcher = new ErrorStateMatcher();

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public appConfigSignUp$ = this._appConfigService.getLiveAppConfigSignUp().pipe(takeUntil(this.stop$));
  appConfigSignIn$ = this._appConfigService.getLiveAppConfigSignIn().pipe(takeUntil(this.stop$));

  public constructor(
    private _formBuilder: FormBuilder,
    private _snackbarService: SnackbarService,
    public _authService: AuthService,
    private _userService: UserService,
    private _appConfigService: AppConfigService
  ) {
    super();
    this.isLoading$.next(true);
    this.appConfigSignUp$.pipe(take(1)).subscribe(() => this.isLoading$.next(false));
    this._authService.cleanupOnLoginAndSignup();
  }

  submit() {
    this.isLoading$.next(true);
    const email: string | null = this.signUpForm.controls.email.value;
    const password: string | null = this.signUpForm.controls.password.value;
    if (!email || !password) {
      this._snackbarService.showErrorMessage('Es ist ein Fehler aufgetreten.');
      throw new Error('no password or no email provided.');
    }
    try {
      this.appConfigSignUp$
        .pipe(
          take(1),
          tap((config: AppConfigSignUpSignIn) => {
            // restriction if generally closed
            if (!config.open) {
              this._snackbarService.showMessage('SignUp is closed', 'error');
              throw new Error('SignUp is closed');
            }
            // restriction if signUp is open, check for whitelist and blacklist
            if (!this._appConfigService.isSignUpSignInAllowed(email, config)) {
              this._snackbarService.showMessage('SignUp is not allowed fot this Mail', 'error');
              throw new Error('SignUp is not allowed fot this Mail');
            }
          }),
          tap(() => this.isLoading$.next(true)),
          switchMap(() => from(createUserWithEmailAndPassword(getAuth(), email, password))),
          tap((userCredential: UserCredential) => (this._userCredential = userCredential)),
          tap((userCredential: UserCredential) => from(sendEmailVerification(userCredential.user))),
          delay(3000), // FIXME: This delay is necessary (maybe smaller) because of onCreateAuthUser event driven cloud Function has to happen before
          switchMap((userCredential: UserCredential) =>
            from(this._authService.addUserMetadata(userCredential, { analytics: { GA_baselineReports: !!this.signUpForm.controls.trackingCheck.value } }))
          ),
          tap(() => this._snackbarService.showMessage('Ihr Benutzerkonto wurde erfolgreich erstellt. Bitte bestätigen Sie ihre Email-Adresse.', 'success')),
          delay(2000), // FIXME: Is this delay necessary?
          tap(() => {
            this.isLoading$.next(false);
            this.signUpMode = false;
            this._authService.logout({ navigate: false, message: false });
          })
        )
        .subscribe();
    } catch (error) {
      this.handleError(error);
    }
  }

  public async send() {
    if (this._userCredential) {
      await sendEmailVerification(this._userCredential.user);
    } else {
      throw new Error('No Auth-User');
    }
  }

  private handleError(error: unknown) {
    if (!(error as FirebaseError)?.code) {
      this._snackbarService.showErrorMessage('Es ist ein Fehler aufgetreten.');
      return handleBasicError(error);
    }
    switch ((error as FirebaseError).code) {
      case 'auth/email-already-in-use':
        this._snackbarService.showErrorMessage('Ein Benutzerkonto mit dieser E-Mail Adresse existiert bereits.');
        handleBasicError(error);
      // ... todo extend error handling
    }
  }
}
