<div [formGroup]="generalFormGroup" class="mat-elevation-z4">
  <div class="row tab-content">
    <div class="col padding-right">
      <mat-form-field class="width-100 smaller-font">
        <mat-label>Projektname</mat-label>
        <input matInput formControlName="name" data-cy="input-tab-general-name" />
      </mat-form-field>
      <mat-form-field class="width-100 smaller-font">
        <mat-label>Projektnummer</mat-label>
        <input matInput formControlName="number" data-cy="input-tab-general-number" />
      </mat-form-field>
      <mat-form-field class="width-100 smaller-font">
        <mat-label>Antragsnummer</mat-label>
        <input matInput formControlName="applicationNumber" data-cy="input-tab-general-applicationNumber" />
      </mat-form-field>
      <mat-form-field class="width-100 smaller-font">
        <mat-label>Baukosten in €</mat-label>
        <eule-comma-decimal-input formControlName="costs"></eule-comma-decimal-input>
      </mat-form-field>
      <mat-form-field class="width-100 smaller-font">
        <mat-label>Fertigstellungsgrad Innenausbau in %</mat-label>
        <input matInput formControlName="degreeOfCompletionOfInteriorFittings"
               data-cy="input-tab-general-degreeOfCompletionOfInteriorFittings" />
        @if (generalFormGroup.get('degreeOfCompletionOfInteriorFittings')?.hasError('maxFixedValueExceeded') ||
        generalFormGroup.get('degreeOfCompletionOfInteriorFittings')?.hasError('min')) {
          <mat-error>Wert muss zwischen 0 und 100 liegen</mat-error>
        }
        @if (generalFormGroup.get('degreeOfCompletionOfInteriorFittings')?.hasError('pattern')) {
          <mat-error>Zahlenfeld</mat-error>
        }
      </mat-form-field>
      <quill-editor
        class="w-100-percent filled-text-field-background small-radius flat-bottom"
        style="height: 173px"
        formControlName="description"
        placeholder="Projekt Beschreibung">
        <div quill-editor-toolbar>
          <eule-custom-quill-editor-toolbar-set
            toolbarSet="custom"
            [allConfigPropsActivated]="true"
            [toolbarConfig]="{
              hasHeadingPicker: false,
              hasImagePicker: false,
            }"
          />
        </div>
      </quill-editor>
    </div>
    <div class="col">
      <div class="project-image-container center">
        <img id="project-image" [src]="projectImage$ | async" alt="'picture of house'" />
        <button mat-icon-button (click)="editProjectImage()" class="hover-button" data-cy="button-tab-general-image">
          <mat-icon>upload</mat-icon>
        </button>
      </div>

      <div class="row">
        <mat-form-field class="width-75 smaller-font street">
          <mat-label>Straße</mat-label>
          <input matInput formControlName="addressStreet" data-cy="input-tab-general-addressStreet" />
        </mat-form-field>
        <mat-form-field class="width-25 smaller-font">
          <mat-label>Hausnummer</mat-label>
          <input matInput formControlName="addressNumber" data-cy="input-tab-general-addressNumber" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="width-75 smaller-font city">
          <mat-label>Ort</mat-label>
          <input matInput formControlName="addressCity" data-cy="input-tab-general-addressCity" />
        </mat-form-field>
        <mat-form-field class="width-25 smaller-font">
          <mat-label>Postleitzahl</mat-label>
          <input matInput formControlName="addressPostCode" data-cy="input-tab-general-addressPostCode" />
        </mat-form-field>
      </div>
      <mat-form-field class="width-100 smaller-font">
        <mat-label>Bundesland</mat-label>
        <input matInput formControlName="addressState" data-cy="input-tab-general-addressState" />
      </mat-form-field>
      <mat-form-field class="width-100 smaller-font">
        <mat-label>Land</mat-label>
        <input matInput formControlName="addressCountry" data-cy="input-tab-general-addressCountry" />
      </mat-form-field>
    </div>
  </div>
</div>
