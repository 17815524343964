<h2 mat-dialog-title>Abonnement wählen</h2>
<mat-dialog-content id="subscription-selection-dialog-content">
  <stripe-pricing-table
    [attr.pricing-table-id]="pricingTableId"
    [attr.publishable-key]="publishableKey"
    [attr.client-reference-id]="dialogData.clientReferenceId"
    [attr.customer-email]="dialogData.email"
  >
  </stripe-pricing-table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [mat-dialog-close]="undefined" data-cy="button-cancel">Abbrechen</button>
</mat-dialog-actions>
