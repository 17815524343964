import { Injectable } from '@angular/core';
import { AppConfigSignUpSignIn } from '@eeule/eeule-shared';
import { DocumentData, DocumentSnapshot, FirestoreError, doc, onSnapshot } from 'firebase/firestore';
import { Observable } from 'rxjs';
import { FirebaseService } from '../firebase.service';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  constructor(private _firebaseService: FirebaseService) {}

  public getLiveAppConfigSignUp(): Observable<AppConfigSignUpSignIn> {
    const docRef = doc(this._firebaseService.firestore, `appConfig/signUp`);
    return new Observable(observer => {
      return onSnapshot(
        docRef,
        (snapshot: DocumentSnapshot<DocumentData, DocumentData>) => observer.next(snapshot.data() as AppConfigSignUpSignIn),
        (error: FirestoreError) => observer.error(error.message)
      );
    });
  }

  public getLiveAppConfigSignIn(): Observable<AppConfigSignUpSignIn> {
    const docRef = doc(this._firebaseService.firestore, `appConfig/signIn`);
    return new Observable(observer => {
      return onSnapshot(
        docRef,
        (snapshot: DocumentSnapshot<DocumentData, DocumentData>) => observer.next(snapshot.data() as AppConfigSignUpSignIn),
        (error: FirestoreError) => observer.error(error.message)
      );
    });
  }

  public isSignUpSignInAllowed(email: string, config: AppConfigSignUpSignIn): boolean {
    // if generally closed, return false
    if (!config.open) return false;

    const mailDomain = '@' + email.split('@')[1];
    // if open but has whitelist and mail is on whitelist, return true
    if (config.whiteList.length > 0 && (config.whiteList.includes(email) || config.whiteList.includes(mailDomain))) {
      return true;
    }
    // if was not previously on whitelist, but there is a whitelist, return false
    else if (config.whiteList.length > 0) return false;
    // if there was no whitelist but there is a blacklist and email is included in blacklist, return false.
    // this means also a whitelist is superior over a blacklist
    if (config.blackList.length > 0 && (config.blackList.includes(email) || config.blackList.includes(mailDomain))) {
      return false;
    }
    // if there was no whitelist or mail is not on blacklist, return true
    return true;
  }
}
