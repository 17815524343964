import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DescriptionGroup, MixedUsageProfile } from '@eeule/eeule-shared';

@Injectable({
  providedIn: 'root',
})
export class NewProjectHelperService {
  constructor(private _formBuilder: FormBuilder) {}

  /**
   *
   * @deprecated consume values from Backend
   * @returns {Array<DescriptionGroup>}
   *
   * @memberOf NewProjectHelperService
   */
  public getBuildingDescriptionGroups(): Array<DescriptionGroup> {
    return [
      {
        id: crypto.randomUUID(),
        title: 'Funktionale Beschreibung',
        attestations: [
          {
            id: 'Erläuterungsbericht_Architektur',
            checked: false,
            description: 'Erläuterungsbericht Architektur',
          },
          {
            id: 'Erläuterung_Gebäudefunktion',
            checked: false,
            description: 'Erläuterung zu Funktionen des Gebäudes',
          },
          {
            id: 'Erläuterung_Gebäudenutzung',
            checked: false,
            description: 'Erläuterung zur Nutzung des Gebäudes',
          },
          {
            id: 'Erläuterung_Nutzeranforderungen',
            checked: false,
            description: 'Erläuterung zur Erfüllung der Anforderungen der Nutzer',
          },
        ],
        descriptions: [
          {
            title: 'Welches Ziel wird mit dem Projekt verfolgt?',
            description: '',
          },
          {
            title: 'Welche Funktionen hat das Gebäude?',
            description: '',
          },
          {
            title: 'Wie wird das Gebäude genutzt?',
            description: '',
          },
          {
            title: 'Welche Anforderungen des Nutzers werden erfüllt?',
            description: '',
          },
        ],
      },
      {
        id: crypto.randomUUID(),
        title: 'Konstruktive Ausführung',
        attestations: [
          {
            id: 'Primäre_Tragkonstruktion',
            checked: false,
            description: 'Primäre Tragkonstruktion (Wände, Stützen, Decken, Dach)',
          },
          {
            id: 'Wandbildnder',
            checked: false,
            description: 'Wandbildner (Außenwand, Innenwände tragend und nicht tragend)',
          },
          {
            id: 'Decken_Dächer',
            checked: false,
            description: 'Decken, Dächer',
          },
          {
            id: 'Fassadengestaltung',
            checked: false,
            description: 'Fassadengestaltung /-ausführung, transparenter Anteil (Glasanteil)',
          },
          {
            id: 'Spezifische_Anforderungen_Konstruktion',
            checked: false,
            description: 'Spezifische Anforderungen an die Konstruktion, Bauphysik und Teilbarkeit (Flexibilität)',
          },
        ],
        descriptions: [
          {
            title: 'Beschreibung',
            description: '',
          },
        ],
      },
      {
        id: crypto.randomUUID(),
        title: 'Technische Versorgung',
        attestations: [
          {
            id: 'Raumheizungsanlge',
            checked: false,
            description: 'Raumheizungsanlage inkl. Wärmeübergabe / Energieträger Raumheizung',
          },
          {
            id: 'Warmwasseranlage',
            checked: false,
            description: 'Warmwasseranlage / Energieträger Warmwasser',
          },
          {
            id: 'Kühlungsanlage',
            checked: false,
            description: 'Kühlungsanlage / Energieträger Kühlung',
          },
          {
            id: 'Lüftungsanlage',
            checked: false,
            description: 'Lüftungsanlage (ggf. inkl. Be- u. Entfeuchtung)',
          },
          {
            id: 'Beleuchtungsanlage',
            checked: false,
            description: 'Beleuchtungsanlage',
          },
        ],
        descriptions: [
          {
            title: 'Beschreibung',
            description: '',
          },
        ],
      },
      {
        id: crypto.randomUUID(),
        title: 'Energie',
        attestations: [
          {
            id: 'GEG-Nachweis',
            checked: false,
            description: 'GEG-Nachweis',
          },
          {
            id: 'Energieausweis',
            checked: false,
            description: 'Energieausweis',
          },
        ],
        descriptions: [
          {
            title: 'Beschreibung',
            description: '',
          },
        ],
      },
    ];
  }

  /**
   *
   * @deprecated consume values from Backend
   * @returns {Array<DescriptionGroup>}
   *
   * @memberOf NewProjectHelperService
   */
  public getImagesDescriptionGroups(): Array<DescriptionGroup> {
    return [
      {
        id: crypto.randomUUID(),
        title: 'Gebäudefotos von allen 4 Seiten',
        attestations: [],
        descriptions: [
          {
            title: 'Beschreibung',
            description: '',
          },
        ],
      },
      {
        id: crypto.randomUUID(),
        title: 'Fotos vom Dach bzgl. Technikaufbauten, PV und Begrünung',
        attestations: [],
        descriptions: [
          {
            title: 'Beschreibung',
            description: '',
          },
        ],
      },
      {
        id: crypto.randomUUID(),
        title: 'Heizungsraum (Heizungsanlage inkl. Bodenbeläge)',
        attestations: [],
        descriptions: [
          {
            title: 'Beschreibung',
            description: '',
          },
        ],
      },
    ];
  }

  /**
   *
   * @deprecated consume values from Backend
   * @returns {Array<DescriptionGroup>}
   *
   * @memberOf NewProjectHelperService
   */
  public getCertificationDescriptionGroups(): Array<DescriptionGroup> {
    return [
      {
        id: '0_Allgemein',
        title: 'Allgemein',
        attestations: [
          {
            id: 'Erläuterung_des_Auditors',
            checked: false,
            description: 'Zu jedem Kriterium ist eine Übersicht bzw. Erläuterung des Auditors einzureichen',
          },
          {
            id: 'Erläuterung_beabsichtigte_Qualitäten_Gebäude-Ökobilanz',
            checked: false,
            description: 'Erläuterung beabsichtigte Qualitäten Gebäude-Ökobilanz (Vorzertifikat)',
          },
          {
            id: 'Erläuterung_beabsichtigte_Qualitäten_Baustoffe',
            checked: false,
            description: 'Erläuterung beabsichtigte Qualitäten Baustoffe (Vorzertifikat)',
          },
          {
            id: 'Erläuterung_beabsichtigte_Qualitäten_Trinkwasserbedarf',
            checked: false,
            description: 'Erläuterung beabsichtigte Qualitäten Trinkwasserbedarf und Abwasseraufkommen (Vorzertifikat)',
          },
          {
            id: 'Erläuterung_beabsichtigte_Qualitäten_Lebenszykluskosten',
            checked: false,
            description: 'Erläuterung beabsichtigte Qualitäten Lebenszykluskosten (Vorzertifikat)',
          },
          {
            id: 'Erläuterung_beabsichtigte_Qualitäten_Thermischer_Komfort',
            checked: false,
            description: 'Erläuterung beabsichtigte Qualitäten Thermischer Komfort und Raumakkustik (Vorzertifikat)',
          },
          {
            id: 'Erläuterung_beabsichtigte_Qualitäten_Tageslichtverfügbarkeit',
            checked: false,
            description: 'Erläuterung beabsichtigte Qualitäten Tageslichtverfügbarkeit des Gebäudes (Vorzertifikat)',
          },
          {
            id: 'Erläuterung_beabsichtigte_Qualitäten_Barrierefreiheit',
            checked: false,
            description: 'Erläuterung beabsichtigte Qualitäten Barrierefreiheit (Vorzertifikat)',
          },
          {
            id: 'Erläuterung_beabsichtigte_Qualitäten_Mindestanforderungen_Version23',
            checked: false,
            description: 'Erläuterung beabsichtigte Qualitäten Mindestanforderungen Version 23 (Vorzertifikat)',
          },
          {
            id: 'Erläuterung_beabsichtigte_Qualitäten_Mindestanforderungen_Platin',
            checked: false,
            description: 'Erläuterung beabsichtigte Qualitäten Mindestanforderungen Platin (Vorzertifikat)',
          },
          {
            id: 'Absichtserklärung_Themenfelder',
            checked: false,
            description: 'Absichtserklärung Themenfelder (Vorzertifikat)',
          },
        ],
        descriptions: [],
      },
      {
        id: '1_System',
        title: 'System',
        attestations: [],
        descriptions: [],
      },
      {
        id: '2_ESG_Verifikation',
        title: 'ESG-Verifikation',
        attestations: [
          {
            id: 'Erläuterung_des_Auditors',
            checked: false,
            description: 'Zu jedem Kriterium ist eine Übersicht bzw. Erläuterung des Auditors einzureichen',
          },
        ],
        descriptions: [],
      },
      {
        id: '3_Qualitautssiegel_Nachhaltige_Gebauude',
        title: 'Qualitätssiegel Nachhaltige Gebäude',
        attestations: [
          {
            id: 'Erläuterung_des_Auditors',
            checked: false,
            description:
              'rechtlich verbindliche Absichtserklärung zu der beabsichtigten Qualität der besonderen Anforderungen (jeweils pro Anforderung erläutert)',
          },
        ],
        descriptions: [],
      },
    ];
  }

  /**
   *
   * @deprecated consume values from Backend
   * @returns {Array<MixedUsageProfile>}
   *
   * @memberOf NewProjectHelperService
   */
  public getUsageProfileWithDescriptionGroup(usageProfile: MixedUsageProfile): MixedUsageProfile {
    const group: DescriptionGroup = {
      id: usageProfile.usageProfile,
      title: usageProfile.usageProfileType,
      attestations: [
        {
          id: 'Nachweise_zur_Definition_des_Nutzungsprofils',
          checked: false,
          description: 'Nachweise zur Definition des Nutzungsprofils',
        },
        {
          id: 'Nachweise_zur_Definition_der_Systemgrenze',
          checked: false,
          description: 'Nachweise zur Definition der Systemgrenze',
        },
      ],
      descriptions: [
        {
          title: 'Definition',
          description: '',
        },
      ],
    };
    return { ...usageProfile, descriptionGroup: group };
  }
}
