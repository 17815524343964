<div class="chart-wrapper flexbox large-gap align-center">
  <div class="donut-chart discipline">
    @if (disciplineChartData) {
      <div class="label">Disziplin</div>
      <div class="chart">
        <eule-donut-chart
          chartId="disciplineTasksChart"
          colorSetType="deepOrange"
          [height]="defaultChartHeight"
          [chartData]="disciplineChartData"
          [donutYPosition]="donutYPosition"
          [colorPaletteStartIndex]="3"
          [numberOfVisibleLegendItems]="numberOfVisibleLegendItems"
          [tooltipPositionOffsetY]="tooltipPositionOffsetY"
        />
      </div>
    }
  </div>

  <div class="donut-chart type">
    @if (typeChartData) {
      <div class="label">Typ</div>
      <div class="chart">
        <eule-donut-chart
          chartId="typeTasksChart"
          colorSetType="blue"
          [height]="defaultChartHeight"
          [chartData]="typeChartData"
          [donutYPosition]="donutYPosition"
          [numberOfVisibleLegendItems]="numberOfVisibleLegendItems"
          [tooltipPositionOffsetY]="tooltipPositionOffsetY"
        />
      </div>
    }
  </div>

  <div class="donut-chart status">
    @if (statusChartData) {
      <div class="label">Status</div>
      <div class="chart">
        <eule-donut-chart
          chartId="statusTasksChart"
          [height]="defaultChartHeight"
          [chartData]="statusChartData"
          [donutYPosition]="donutYPosition"
          [numberOfVisibleLegendItems]="numberOfVisibleLegendItems"
          [tooltipPositionOffsetY]="tooltipPositionOffsetY"
        />
      </div>
    }
  </div>

  <div class="donut-chart priority">
    @if (priorityChartData) {
      <div class="label">Priorität</div>
      <div class="chart">
        <eule-donut-chart
          chartId="priorityTasksChart"
          colorSetType="deepPurple"
          [height]="defaultChartHeight"
          [chartData]="priorityChartData"
          [donutYPosition]="donutYPosition"
          [numberOfVisibleLegendItems]="numberOfVisibleLegendItems"
        />
      </div>
    }
  </div>
</div>
