import { Injectable } from '@angular/core';
import { CertificationType } from '@eeule/eeule-shared';
import { DocumentData, FirestoreError, QueryDocumentSnapshot, QuerySnapshot, collection, onSnapshot } from 'firebase/firestore';
import { Observable } from 'rxjs';
import { FirebaseService } from '../firebase.service';

@Injectable({
  providedIn: 'root',
})
export class SystemConfigService {
  constructor(private _firebaseService: FirebaseService) {}

  public getLiveSystemConfigTypesEuTaxonomy(): Observable<CertificationType[]> {
    const colRef = collection(this._firebaseService.firestore, `systemConfig/certificationTypes/euTaxonomy`);

    return new Observable(observer => {
      return onSnapshot(
        colRef,
        (usersArraySnapshot: QuerySnapshot<DocumentData, DocumentData>) =>
          observer.next(usersArraySnapshot.docs.map((docSnap: QueryDocumentSnapshot<DocumentData, DocumentData>) => docSnap.data() as CertificationType) || []),
        (error: FirestoreError) => observer.error(error.message)
      );
    });
  }

  public getLiveSystemConfigTypesQng(): Observable<CertificationType[]> {
    const colRef = collection(this._firebaseService.firestore, `systemConfig/certificationTypes/qng`);

    return new Observable(observer => {
      return onSnapshot(
        colRef,
        (usersArraySnapshot: QuerySnapshot<DocumentData, DocumentData>) =>
          observer.next(usersArraySnapshot.docs.map((docSnap: QueryDocumentSnapshot<DocumentData, DocumentData>) => docSnap.data() as CertificationType) || []),
        (error: FirestoreError) => observer.error(error.message)
      );
    });
  }

  public getLiveSystemConfigTypesProjectCertificationLevels(): Observable<CertificationType[]> {
    const colRef = collection(this._firebaseService.firestore, `systemConfig/certificationTypes/projectCertificationLevels`);

    return new Observable(observer => {
      return onSnapshot(
        colRef,
        (usersArraySnapshot: QuerySnapshot<DocumentData, DocumentData>) =>
          observer.next(usersArraySnapshot.docs.map((docSnap: QueryDocumentSnapshot<DocumentData, DocumentData>) => docSnap.data() as CertificationType) || []),
        (error: FirestoreError) => observer.error(error.message)
      );
    });
  }
}
