<div id="auditStartDashboard" class="wrapper flexbox direction-column default-gap h-100-percent">
  <eule-audit-summary [tasksCountVsDone]="tasksCountVsDone" />
  @if (tasks?.length) {
    <mat-card class="custom-card chart-card flex-grow-1 full-width-header">
      <mat-card-header class="card-header flexbox">
        <mat-card-title class="card-title flexbox small-gap align-center justify-spaced">
          <div class="title">Aufgaben</div>
          @if (taskDataIsLoading) {
            <mat-spinner diameter="20" />
          }
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="card-content chart-card-content">
        <eule-audit-task-data-charts [tasks]="tasks!"/>
      </mat-card-content>
    </mat-card>
  }
</div>
