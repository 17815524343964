import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { AppStoreApp } from '../../services/app.service';

@Component({
  selector: 'eule-app-detail-page',
  standalone: true,
  imports: [],
  templateUrl: './app-detail-page.component.html',
  styleUrl: './app-detail-page.component.scss',
})
export class AppDetailPageComponent {
  appData?: AppStoreApp;
  appSafeUrl?: SafeResourceUrl;

  constructor(private _route: ActivatedRoute, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this._route.queryParams.subscribe((params: Params) => {
      this.appData = params as AppStoreApp;
      const decodedUrl = decodeURIComponent(this.appData.url);
      this.appSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(decodedUrl);
    });
  }
}
