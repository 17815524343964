import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, switchMap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../auth-christian/auth.service';

export interface StripeCreateCustomerPortalSessionData {
  customerId: string;
  projectId: string;
  returnUrl?: string;
}

export interface StripeCustomerPortalSession {
  id: string;
  object: string;
  configuration: string;
  created: number;
  customer: string;
  flow: unknown;
  livemode: boolean;
  locale: unknown;
  on_behalf_of: unknown;
  return_url: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  constructor(private _http: HttpClient, private _authService: AuthService) {}

  public createCheckoutSession(priceId: string, internalCustomerId: string, internalProjectId: string) {
    const region = 'europe-west3';
    const firebaseProjectId = environment.firebaseConfig.projectId;
    const functionName = 'callStripeCreateCheckoutSession';
    const functionUrl = `https://${region}-${firebaseProjectId}.cloudfunctions.net/${functionName}`;
    const requestData = { metadata: { priceId: priceId, internalCustomerId: internalCustomerId, projectId: internalProjectId } };

    return this._http.post(functionUrl, requestData);
  }

  public createCustomerPortalSession(customerId: string, projectId: string): Observable<StripeCustomerPortalSession> {
    const region = 'europe-west3';
    const firebaseProjectId = environment.firebaseConfig.projectId;
    const functionName = 'callStripeCreateCustomerPortalSession';
    const functionUrl = `https://${region}-${firebaseProjectId}.cloudfunctions.net/${functionName}`;
    const requestData: StripeCreateCustomerPortalSessionData = {
      customerId: customerId,
      projectId: projectId,
      returnUrl: window.location.href,
    };

    const token$: Observable<string> = from(this._authService.getAuthUser()!.getIdToken());
    return token$.pipe(
      switchMap((token: string) =>
        this._http.post<StripeCustomerPortalSession>(functionUrl, requestData, {
          headers: {
            Authorization: `Bearer ${token}`, // Füge den Auth-Token-Header hinzu
          },
        })
      )
    );
  }
}
