<div class="overflow-hidden " id="projectHome">
  <eule-general-title title="Home" />
  @if (isLoading) {
    <div class="loading-overlay">
      <mat-spinner diameter="50" />
    </div>
  } @else {
    <div class="project-home-wrapper flexbox direction-column default-gap align-center custom-scrollbar">
      <section id="generalData" class="dashboard-section flexbox default-gap align-center">
        <div class="project-image-container">
          @if (projectImage$ | async; as projectImage) {
            <img id="project-image"
                 class="cursor-pointer"
                 alt="project picture"
                 tabindex="0" role="button"
                 [src]="projectImage"
                 (click)="onProjectImageClick()"
                 (keydown)="onProjectImagKeyDown($event)"
            />
          } @else {
            <div class="flexbox centered h-100-percent">
              <mat-spinner diameter="50" />
            </div>
          }
        </div>
        @if (generalData) {
          <div class="general-data-wrapper flex-grow-1">
            <eule-label-value-card
              class="h-100-percent"
              [fields]="generalData"
              [columnCount]="3"
            />
          </div>
        }
      </section>

      <section id="buildingParameters" class="dashboard-section ">
        @if (buildingParameterData) {
          <eule-label-value-card
            class="w-100-percent"
            title="Gebäudeparameter"
            columnWidth="25%"
            [fields]="buildingParameterData"
            [columnCount]="4"
          />
        }
      </section>

      <section id="certification" class="dashboard-section ">
        @if (certificationData) {
          <eule-label-value-card
            class="w-100-percent"
            title="Zertifizierung"
            columnWidth="25%"
            [fields]="certificationData"
            [columnCount]="4"
          />
        }
      </section>
    </div>
  }
</div>
