import { Injectable } from '@angular/core';
import { Auth, User, UserCredential, authState, sendPasswordResetEmail, signInWithEmailAndPassword } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { User as AppUser } from '@eeule/eeule-shared';
import { browserLocalPersistence, browserSessionPersistence, getAuth, setPersistence } from 'firebase/auth';
import { DocumentData, DocumentSnapshot, doc, getDoc } from 'firebase/firestore';
import { BehaviorSubject, Observable, from, lastValueFrom, map, of, switchMap } from 'rxjs';
import { FirebaseService } from '../firebase.service';
import { ProjectService } from '../project.service';
import { SnackbarService } from '../snackbar.service';
import { UserService } from '../user.service';
import { environment } from '../../../../environments/environment';

export interface Metadata {
  analytics: { GA_baselineReports: boolean };
}
@Injectable({
  providedIn: 'root',
})
/**
 * Service to handle authentication related functionalities.
 */
export class AuthService {
  currentUser$: BehaviorSubject<AppUser | null> = new BehaviorSubject<AppUser | null>(null);
  loginMessageSubject$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private _router: Router,
    private _auth: Auth,
    private _firebaseService: FirebaseService,
    private _snackbarService: SnackbarService,
    private _userService: UserService,
    private _projectService: ProjectService
  ) {
    this._init();
  }

  private _init() {
    this._auth.onAuthStateChanged(state => {
      if (state) {
        this._userService.init();
      }
    });
  }

  /**
   * Logs out the current user.
   * @returns {Promise<void>} A promise resolving to void.
   */
  async logout(options = { navigate: true, message: true }): Promise<void> {
    this.cleanupOnLogout();

    return this._auth
      .signOut()
      .then(async () => {
        if (options.message) {
          this._snackbarService.showMessage('Sie wurden ausgeloggt', 'success');
        }
        if (options.navigate) {
          await this._router.navigate(['/login']);
        }
      })
      .catch((error: Error) => {
        throw new Error('Error on logging out', error);
      });
  }

  /**
   * Checks if the user is authenticated.
   * @returns {Observable<boolean>} An observable of boolean indicating the authentication state.
   */
  isAuthenticated(): Observable<boolean> {
    return authState(this._auth).pipe(
      switchMap(user => {
        if (!user?.uid) return of(false);
        return this.getAppUser(user.uid).pipe(
          map(user => {
            this.currentUser$.next((user as AppUser) || null);
            return !!user;
          })
        );
      })
    );
  }

  /**
   * Retrieves the current auth user.
   * @returns {User | null} The current user, or null if no user is authenticated.
   */
  getAuthUser(): User | null {
    return this._auth.currentUser;
  }

  /**
   * Retrieves the current user
   * @param userId
   */
  public getAppUser(userId: string): Observable<AppUser> {
    const docRef = doc(this._firebaseService.firestore, `users/`, userId);
    return from(getDoc(docRef)).pipe(map((userSnap: DocumentSnapshot<DocumentData, DocumentData>) => userSnap.data() as AppUser));
  }

  /**
   * Logs in the user with the provided email and password.
   * @param {string | null | undefined} email - The user's email.
   * @param {string | null | undefined} password - The user's password.
   * @param {boolean} persistentLogin - The user's password.
   * @returns {Promise<UserCredential | void>} A promise resolving to the user credentials.
   */
  public async login(email: string | null | undefined, password: string | null | undefined, persistentLogin?: boolean): Promise<UserCredential | void> {
    if (!email || !password) {
      this._snackbarService.showErrorMessage('Es wurde keine E-Mail Adresse oder kein Passwort übergeben.');
      return;
    }
    return setPersistence(getAuth(), persistentLogin ? browserLocalPersistence : browserSessionPersistence)
      .then(async () => {
        const userCredentials = await signInWithEmailAndPassword(getAuth(), email, password);
        if (!userCredentials.user?.uid) {
          await Promise.reject({ code: 'auth/no-access' });
        }
        const currentUser = await lastValueFrom(this.getAppUser(userCredentials.user.uid));
        if (!currentUser) {
          await Promise.reject({ code: 'auth/no-access' });
        }
        this.loginMessageSubject$.next('');
        if (userCredentials.user.emailVerified) {
          await this._router.navigateByUrl('intern');
        } else {
          // await this._router.navigate(['verify'], { queryParams: { email: userCredentials.user. } });
          await this._router.navigate(['verify']);
        }
        this._snackbarService.showMessage('Sie wurden erfolgreich eingeloggt.', 'success');
        return userCredentials;
      })
      .catch(async error => {
        switch (error.code) {
          case 'auth/invalid-credential': {
            console.error(error);
            const invalidCredentialsMessage: string = 'Die eingegebene E-Mail Adresse und das eingegebene Passwort stimmen nicht überein.';
            this.loginMessageSubject$.next(invalidCredentialsMessage);
            return;
          }
          case 'auth/no-access': {
            console.error(error);
            const noAccessMessage: string = 'Ihr Benutzer hat keinen Zugriff auf das Portal.';
            this.loginMessageSubject$.next(noAccessMessage);
            await this.logout();
            return;
          }
          case 'auth/too-many-requests': {
            console.error(error);
            const tooManyRequestsMessage: string = 'Zu viele Versuche. Versuchen Sie es zu einem späteren Zeitpunkt noch einmal.';
            this.loginMessageSubject$.next(tooManyRequestsMessage);
            return;
          }
          default:
            console.error(error);
            this._snackbarService.showErrorMessage('Es ist ein Fehler beim Login aufgetreten.');
        }
      });
  }

  /**
   * Sends a password reset email to the specified email address.
   * @param {string} email - The email address to which the password reset email will be sent.
   * @returns {Promise<void>} A promise resolving to void.
   */
  async sendPasswordResetEmail(email: string): Promise<void> {
    try {
      await sendPasswordResetEmail(this._auth, email);
      this._snackbarService.showMessage('Der Link zum Zurücksetzen des Passworts wurde gesendet. Bitte überprüfen Sie Ihr Postfach.', 'success');
    } catch (error) {
      this._snackbarService.showErrorMessage(
        'Beim Versenden der E-Mail ist ein Fehler aufgetreten. ' +
          'Bitte überprüfen Sie die eingetragene E-Mail Adresse oder versuchen Sie es später noch einmal.'
      );
      throw new Error();
    }
  }

  public async addUserMetadata(userCredential: UserCredential, metadata: Metadata): Promise<void> {
    const region = 'europe-west3';
    const firebaseProjectId = environment.firebaseConfig.projectId;
    const functionName = 'addUserMetadata';
    const functionUrl = `https://${region}-${firebaseProjectId}.cloudfunctions.net/${functionName}`;

    const user = userCredential.user;
    const idToken = await user.getIdToken(); // Token für Authentifizierung abrufen

    return fetch(functionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ uid: user.uid, metadata }),
    })
      .then((response: Response) => {
        if (!response.ok) {
          console.error('Error adding user metadata:', response.statusText);
        }
      })
      .catch(error => {
        console.error('Error adding metadata:', error);
      });
  }

  public cleanupOnLoginAndSignup() {
    // IMPORTANT ATTENTION this is !CRITICAL! All the Data of the previous logged in user / session needs to be cleaned up
    this._userService.cleanUp();
    this._projectService.cleanUp();
  }
  public cleanupOnLogout() {
    // IMPORTANT ATTENTION this is !CRITICAL! All the Data of the previous logged in user / session needs to be cleaned up
    this._userService.cleanUp();
    this._projectService.cleanUp();
  }

  public getAuth(): Auth {
    return getAuth();
  }
}
