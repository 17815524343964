import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { GeneralTitleComponent } from '../../core/components/general-title/general-title.component';
import { AnalyticsService } from '../../core/services/analytics/analytics.service';
import { ProjectService } from '../../core/services/project.service';
import { StorageService } from '../../core/services/storage.service';
import {
  catchError,
  combineLatest,
  distinctUntilChanged,
  Observable,
  of,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { BaseComponent } from '../../core/components/base/base.component';
import { SnackbarService } from '../../core/services/snackbar.service';
import { CertificationType, DgnbSystem, Project, UsageProfile, User } from '@eeule/eeule-shared';
import {
  LabelValueCardComponent,
  LabelValueField,
} from '../../core/components/label-value-card/label-value-card.component';
import { UserService } from '../../core/services/user.service';
import { getLocaleDateString } from '../../../util/date.helper';
import { parseNumberAsLocaleString } from '../../../util/conversion.helper';
import { UsageProfileService } from '../../core/services/usage-profiles/usage-profile.service';
import { getUsageProfileEnumValue } from '../../../util/enum.helper';
import { LifeCyclePhaseEnum } from '../../enums/LifeCyclePhase.enum';
import { IndicatorService } from '../../core/services/indicator.service';
import { SystemConfigService } from '../../core/services/systemConfig/system-config.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { LeafIconData } from '../../core/components/leaf-icon-list/leaf-icon-list.component';
import { MatDialog } from '@angular/material/dialog';
import {
  ImageViewDialogComponent,
  ImageViewDialogData,
} from '../../core/components/image-view-dialog/image-view-dialog.component';

@Component({
  selector: 'eule-home',
  standalone: true,
  imports: [CommonModule, MatCardModule, GeneralTitleComponent, LabelValueCardComponent, MatProgressSpinner],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent extends BaseComponent implements OnInit {
  /** Project image subject */
  projectImage$?: Observable<string | null>;

  projectImage?: string | null;

  /** Data for the different sections */
  generalData?: LabelValueField[];
  buildingParameterData?: LabelValueField[];
  certificationData?: LabelValueField[];

  isLoading: boolean = false;

  public constructor(
    private _analyticsService: AnalyticsService,
    private _projectService: ProjectService,
    private _storageService: StorageService,
    private _snackbarService: SnackbarService,
    private _userService: UserService,
    private _indicatorService: IndicatorService,
    private _usageProfileService: UsageProfileService,
    private _systemConfigService: SystemConfigService,
    private _dialog: MatDialog,
  ) {
    super();
    this._analyticsService.sendPageView('project-home-page');
  }

  /**
   * Initializes the component and loads project data.
   *
   * This method sets the loading state, subscribes to the project observable, and fetches
   * necessary data such as project image, project owner, usage profiles, DGNB system, and
   * certification levels. It handles errors and updates the component state accordingly.
   */
  ngOnInit() {
    this.projectImage$ = this._projectService.project$.pipe(
      distinctUntilChanged(),
      switchMap((project) => {
        if (!project) return of(null);
        return project.projectImagePath
          ? this._storageService.downloadImage(project.projectImagePath)
          : of('./assets/images/Rendering_Mehrfamilienhaus3.jpeg');
      }),
      tap((imageUrl) => this.projectImage = imageUrl),
      shareReplay(1),
    );

    this._projectService.project$.pipe(
      tap(() => this.isLoading = true),
      switchMap((project) => {
        if (!project) return combineLatest([of(null), of(null), of(null)]);
        return combineLatest([
          of(project),
          this._projectService.getProjectUsers(project.id).pipe(
            switchMap((users) => {
              const projectOwner = users.find((user) => user.id === project.projectOwner);
              if (!projectOwner) return of(null);
              return this._userService.getUser(projectOwner.authUserId);
            }),
          ),
          this._usageProfileService.getUsageProfiles(),
          this._indicatorService.getDgnbSystem(project.dgnbSystem),
          this._systemConfigService.getLiveSystemConfigTypesProjectCertificationLevels(),
          this._systemConfigService.getLiveSystemConfigTypesEuTaxonomy(),
          this._systemConfigService.getLiveSystemConfigTypesQng(),
        ]);
      }),
      catchError((error) => {
        this._snackbarService.showErrorMessage('Error loading project');
        console.error(error);
        return throwError(() => error);
      }),
      tap(() => this.isLoading = false),
      takeUntil(this.stop$),
    ).subscribe(([
                   project,
                   projectOwner,
                   usageProfiles,
                   dgnbSystem,
                   certificationLevels,
                   esgCertificationLevels,
                   qngCertificationLevels,
                 ]) => {
      if (!project) return; // return gracefully if no project was emitted and wait for project emission. (Project fetch has it`s own error handling).
      this.generateSectionData(
        project,
        projectOwner,
        dgnbSystem,
        usageProfiles?.data,
        certificationLevels,
        esgCertificationLevels,
        qngCertificationLevels,
      );
    });
  }

  /**
   * Handles the click event on the project image.
   *
   * This method opens a dialog to display the project image.
   */
  onProjectImageClick() {
    this.openProjectImageDialog();
  }

  /**
   * Handles the keydown event on the project image.
   *
   * This method checks if the pressed key is 'Enter' or 'Space'. If so, it opens a dialog to display the project image
   * and prevents the default action of the event.
   *
   * @param {KeyboardEvent} event - The keyboard event object.
   */
  onProjectImagKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      this.openProjectImageDialog();
      event.preventDefault();
    }
  }

  private openProjectImageDialog() {
    if (!this.projectImage) {
      console.error("project or dummy image expected but was not provided");
      return; // return gracefully
    }
    this._dialog.open<ImageViewDialogComponent, ImageViewDialogData>(
      ImageViewDialogComponent,
      {
        data: {
          imageUrl: this.projectImage,
        },
        height: '60vh',
      },
    );
  }

  /**
   * Generates data for different sections of the project.
   *
   * This function calls other functions to generate general data, building parameters data,
   * and certification data for the project.
   *
   * @param {Project} project - The project data object.
   * @param {User | null} owner - The owner of the project, or null if not available.
   * @param {DgnbSystem} dgnbSystem - The DGNB system data object.
   * @param {UsageProfile[] | null} [usageProfiles] - The array of usage profiles, or null/undefined if not available.
   * @param {CertificationType[] | undefined} [certificationLevels] - The array of certification levels, or undefined if not available.
   * @param {CertificationType[] | undefined} [esgCertificationLevels] - The array of ESG certification levels, or undefined if not available.
   * @param {CertificationType[] | undefined} [qngCertificationLevels] - The array of QNG certification levels, or undefined if not available.
   */
  private generateSectionData(
    project: Project,
    owner: User | null,
    dgnbSystem: DgnbSystem,
    usageProfiles?: UsageProfile[] | null,
    certificationLevels?: CertificationType[],
    esgCertificationLevels?: CertificationType[],
    qngCertificationLevels?: CertificationType[],
  ): void {
    this.generateGeneralData(project, owner);
    this.generateBuildingParametersData(project, usageProfiles);
    this.generateCertificationData(project, dgnbSystem, certificationLevels, esgCertificationLevels, qngCertificationLevels);
  }

  /**
   * Generates general data for the project.
   *
   * This function creates an array of label-value pairs containing general information about the project.
   * It includes the project name, number, owner, location, application number, and time interval.
   *
   * @param {Project} project - The project data object.
   * @param {User | null} owner - The owner of the project, or null if not available.
   */
  private generateGeneralData(project: Project, owner: User | null) {
    const projectOwner = owner?.firstName && owner?.lastName
      ? `${owner.firstName} ${owner.lastName}`
      : owner?.email || '-';

    const startDate: string | null = project.leistungsPhasen?.[0]
      ? getLocaleDateString(project.leistungsPhasen[0], true)
      : null;

    const endDate: string | null = project.handoverDate
      ? getLocaleDateString(project.handoverDate, true)
      : null;

    let timeIntervalString: string = '-';
    if (startDate && endDate) {
      timeIntervalString = `${startDate} - ${endDate}`;
    } else if (startDate) {
      timeIntervalString = `ab ${startDate}`;
    } else if (endDate) {
      timeIntervalString = `bis ${endDate}`;
    }

    this.generalData = [
      { label: 'Projektname', value: project.name || '-' },
      { label: 'Projektnummer', value: project.number || '-' },
      { label: 'Eigentümer', value: projectOwner },
      { label: 'Standort', value: project.addressCity || '-' },
      { label: 'Antragsnummer', value: project.applicationNumber || '-' },
      { label: 'Zeitraum', value: timeIntervalString || '-' },
    ];
  }

  /**
   * Generates building parameters data for the project.
   *
   * This function calculates the total values for BGF, KGF, NRF, and BRI from the project's usage profiles.
   * It also generates an array of usage profile strings and creates an array of label-value pairs containing
   * building parameters information such as number of floors, construction costs, and residential units.
   *
   * @param {Project} project - The project data object.
   * @param {UsageProfile[] | null | undefined} usageProfiles - The array of usage profiles, or null/undefined if not available.
   */
  private generateBuildingParametersData(project: Project, usageProfiles: UsageProfile[] | null | undefined) {
    const bgf: number = project.usageProfiles?.map((profile) => profile.bgf)
      .reduce((acc, val) => (acc || 0) + (val || 0), 0) || 0;

    const kgf: number = project.usageProfiles?.map((profile) => profile.kgf)
      .reduce((acc, val) => (acc || 0) + (val || 0), 0) || 0;

    const nrf: number = project.usageProfiles?.map((profile) => profile.nrf)
      .reduce((acc, val) => (acc || 0) + (val || 0), 0) || 0;

    const bri: number = project.usageProfiles?.map((profile) => profile.bri)
      .reduce((acc, val) => (acc || 0) + (val || 0), 0) || 0;

    const usageProfileLeafIcons: LeafIconData[] | undefined = project.usageProfiles?.map((profile) => {
      const upString: string | undefined = usageProfiles?.find((up) => up.id === profile.usageProfile)?.name;
      if (!upString) return undefined;
      const leafValue: string | undefined = getUsageProfileEnumValue(upString);
      return { value: leafValue?.slice(0, 2).toUpperCase() || '', tooltip: leafValue || '' };
    }).filter((leafIconData) => leafIconData?.value?.length) as LeafIconData[] | undefined;

    this.buildingParameterData = [
      { label: 'Geschosse', value: project.numberOfFloors?.toString() || '-' },
      {
        label: 'Baukosten', value: project.costs
          ? parseNumberAsLocaleString(parseFloat(project.costs?.toString()))
          : '-',
      },
      { label: 'BGR (R) Gesamt [m2]', value: parseNumberAsLocaleString(bgf) },
      { label: 'KGF (R) Gesamt [m2]', value: parseNumberAsLocaleString(kgf) },
      { label: 'Wohneinheiten', value: project.numberOfResidentialUnits?.toString() || '-' },
      {
        label: 'Nutzungsprofile',
        value: usageProfileLeafIcons?.length ? usageProfileLeafIcons : '-',
        isLeafIconArray: !!usageProfileLeafIcons?.length,
      },
      { label: 'NRF (R) Gesamt [m2]', value: parseNumberAsLocaleString(nrf) },
      { label: 'BRI Gesamt [m3]', value: parseNumberAsLocaleString(bri) },
    ];
  }

  /**
   * Generates certification data for the project.
   *
   * This function creates an array of label-value pairs containing certification information about the project.
   * It includes details such as the life cycle phase, BGF size, certification level, ESG verification, certification system,
   * deconstruction status, DGNB system, and quality seal for sustainable buildings.
   *
   * @param {Project} project - The project data object.
   * @param {DgnbSystem} dgnbSystem - The DGNB system data object.
   * @param {CertificationType[] | undefined} certificationLevels - The array of certification levels, or undefined if not available.
   * @param {CertificationType[] | undefined} esgCertificationLevels - The array of ESG certification levels, or undefined if not available.
   * @param {CertificationType[] | undefined} qngCertificationLevels - The array of QNG certification levels, or undefined if not available.
   */
  private generateCertificationData(
    project: Project,
    dgnbSystem: DgnbSystem,
    certificationLevels?: CertificationType[],
    esgCertificationLevels?: CertificationType[],
    qngCertificationLevels?: CertificationType[],
  ) {
    const certificationLevel: CertificationType | undefined
      = certificationLevels?.find((level) => level.id === project.selectedCertificationLevel);

    //FIXME! wrong typing -> type mismatch db <-> Project.interface
    const esgCertificationLevel: CertificationType | undefined
      = esgCertificationLevels?.find((level) => level.id === project.selectedErfuellungsGradEuTaxonomy as unknown as string);

    //FIXME! wrong typing -> type mismatch db <-> Project.interface
    const qngCertificationLevel: CertificationType | undefined
      = qngCertificationLevels?.find((level) => level.id === project.selectedErfuellungsGradQng as unknown as string);

    this.certificationData = [
      {
        label: 'Bauphase', value: project.lifeCyclePhase
          ? LifeCyclePhaseEnum[project.lifeCyclePhase as unknown as keyof typeof LifeCyclePhaseEnum]
          : '-',
      },
      { label: 'BGF > 5.000 m2', value: project.bgfBigger5000 ? 'Ja' : 'Nein' },
      { label: 'Zertifikatsstufe', value: certificationLevel?.value || '-' },
      { label: 'Qualitätssiegel Nachhaltige Gebäude', value: qngCertificationLevel?.value || 'Nein' },
      { label: 'Zertifizierungssystem', value: dgnbSystem.name || '-' },
      { label: 'Mit Rückbau', value: project.withDeconstruction || project.circularEconomy ? 'Ja' : 'Nein' },
      { label: 'DGNB', value: project.selectedErfuellungsGradSystem?.name || '-' },
      { label: 'ESG-Verifikation', value: esgCertificationLevel?.value || '-' },
    ];
  }
}
