import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import {
  IndicatorTaskDialogComponent,
} from '../../../projects/components/indicator-task-dialog/indicator-task-dialog.component';

export type ImageViewDialogData = {
  imageUrl: string,
  imageTitle?: string,
  imageAltText?: string,
}

@Component({
  selector: 'eule-image-viewer',
  standalone: true,
  imports: [
    MatDialogContent,
  ],
  templateUrl: './image-view-dialog.component.html',
  styleUrl: './image-view-dialog.component.scss'
})
export class ImageViewDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ImageViewDialogData,
    public dialogRef: MatDialogRef<IndicatorTaskDialogComponent>,
  ) {}
}
