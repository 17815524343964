import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTab, MatTabGroup, MatTabLabel } from '@angular/material/tabs';
import { ProjectUser, RoleAssignment, UserStatus } from '@eeule/eeule-shared';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  forkJoin,
  lastValueFrom,
  map,
  mergeMap,
  of,
  startWith,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { retrieveErrorMessageFromUnknownError } from '../../../../util/error.helper';
import { BaseComponent } from '../../../core/components/base/base.component';
import { ConfirmDialogComponent, ConfirmDialogData } from '../../../core/components/confirm-dialog/confirm-dialog.component';
import { GeneralTitleComponent } from '../../../core/components/general-title/general-title.component';
import { SearchBarComponent } from '../../../core/components/search-bar/search-bar.component';
import { CustomTooltipDirective } from '../../../core/directives/custom-tooltip.directive';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';
import { PermissionService } from '../../../core/services/permission.service';
import { ProjectService, StripeInfo } from '../../../core/services/project.service';
import { RoleService } from '../../../core/services/role.service';
import { SnackbarService } from '../../../core/services/snackbar.service';
import { StripeCustomerPortalSession, StripeService } from '../../../core/services/stripe/stripe.service';
import { ProjectUserDisplay, UserService } from '../../../core/services/user.service';
import {
  AddUserToProjectDialogComponent,
  AddUserToProjectDialogComponentConfig,
  AddUserToProjectDialogResult,
} from '../../components/add-user-to-project-dialog/add-user-to-project-dialog.component';
import { LicenseAdministrationComponent } from '../../components/license-administration/license-administration.component';
import { UsersListComponent } from '../../components/users-list/users-list.component';

export type UserListTableData = Partial<ProjectUserDisplay> & {
  id: string;
  name: string;
  status?: string;
  userRole?: string;
};

@Component({
  selector: 'eule-users-list-page',
  standalone: true,
  imports: [
    CommonModule,
    GeneralTitleComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    SearchBarComponent,
    MatCard,
    MatCardContent,
    UsersListComponent,
    MatTabGroup,
    MatTab,
    MatTabLabel,
    LicenseAdministrationComponent,
    CustomTooltipDirective,
  ],
  templateUrl: './users-list-page.component.html',
  styleUrl: './users-list-page.component.scss',
})
export class UsersListPageComponent extends BaseComponent implements OnInit {
  public isCustomerPortalLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public searchValue = '';
  public selectedTabIndex: number = 0;
  public projectUsers: ProjectUserDisplay[] = [];
  public filterValue: string = '';
  public currentUser: ProjectUserDisplay | null = null;
  public availableLicenses?: number;
  public usedLicenses?: number;
  public refetch$ = new Subject<void>();

  @ViewChild(MatPaginator) paginator!: MatPaginator | null;
  @ViewChild(MatSort) sort!: MatSort | null;

  public constructor(
    public _permissionService: PermissionService,
    public _projectService: ProjectService,
    private _analyticsService: AnalyticsService,
    private _userService: UserService,
    private _roleService: RoleService,
    private _dialog: MatDialog,
    private _snackbarService: SnackbarService,
    private _stripeService: StripeService
  ) {
    super();
    this._analyticsService.sendPageView('leistungsphasen-page');
  }

  ngOnInit() {
    this._loadData();
  }

  /**
   * Updates the search value used to filter the user list.
   *
   * @param {string} searchValue - The value to filter the user list by.
   */
  public search(searchValue: string) {
    this.searchValue = searchValue;
  }

  /**
   * Opens a dialog to invite a new user to the project.
   * Sends an analytics event when the dialog is opened.
   * Invites the user by their email if a result is returned from the dialog.
   */
  public openAddUserDialog() {
    this._analyticsService.sendEvent('button_click', {
      label: 'users-list-page_invite-user',
    });
    const dialogRef = this._dialog.open<
      AddUserToProjectDialogComponent,
      AddUserToProjectDialogComponentConfig,
      AddUserToProjectDialogResult | null
    >(AddUserToProjectDialogComponent, {
      width: '480px',
      maxWidth: '70vw',
      data: {},
    });

    dialogRef
      .afterClosed()
      .pipe(
        switchMap(result => (result ? this._projectService.inviteUserByEmail(result.invitedUserEmail) : of(null))),
        catchError(err => {
          this.showInviteUserErrorMessage(err);
          return throwError(() => err);
        }),
        take(1)
      )
      .subscribe(() => {
        this._snackbarService.showMessage('Einladung wurde versendet.', 'success');
      });
  }

  /**
   * Loads the users for the current project and updates the component state.
   * Displays an error message if there is an issue loading user roles.
   *
   * @private
   */
  private _loadData() {
    this.isLoading$.next(true);
    combineLatest([this.refetch$.pipe(startWith(null)), this._projectService.project$])
      .pipe(
        switchMap(([, project]) => {
          if (!project) return [];
          return combineLatest([
            this._projectService.getLiveAllProjectUsersDisplay(project.id).pipe(
              mergeMap((users: ProjectUserDisplay[]) => {
                const mergedProjectUserDisplay$ = users
                  .filter(user => user.authUserId)
                  .map(user => {
                    return this._roleService.getProjectRoleAssignmentByUserId(project.id, user.authUserId!).pipe(
                      tap(roleAssignmentsRes => {
                        if (roleAssignmentsRes.hasError) {
                          this._snackbarService.showErrorMessage('Fehler beim Laden einer Benutzerrolle.');
                          const error = retrieveErrorMessageFromUnknownError(roleAssignmentsRes.error);
                          throw new Error(`error while loading user roles: ${error}`);
                        }
                      }),
                      map(roleAssignmentsRes => {
                        return {
                          ...user,
                          roles: roleAssignmentsRes?.data?.roles.map(role => role?.id || 'viewer') || ['viewer'],
                          roleAssignment: roleAssignmentsRes?.data as RoleAssignment,
                        };
                      })
                    );
                  });
                return forkJoin(mergedProjectUserDisplay$);
              }),
              tap(users => {
                this.currentUser = users.find(user => user.authUserId === this._userService.euleUser$.value?.id) || null;
              })
            ),
            this._projectService.getLiveStripeProjectInfo(project.id).pipe(map(stripeInfo => stripeInfo[0] || null)),
          ]);
        }),
        takeUntil(this.stop$)
      )
      .subscribe(([users, stripeInfo]) => {
        this.projectUsers = users;
        this.availableLicenses = stripeInfo?.currentlyLicensedUsers || 0;
        this.usedLicenses = users.filter(user => user.roles.includes('member') || user.roles.includes('auditor')).length;
        this.isLoading$.next(false);
      });
  }

  /**
   * Deletes a user from the project after confirming the action with the user.
   * Displays an error message if the user is the project owner or if the deletion fails.
   *
   * @param {ProjectUser} user - The user to delete from the project.
   * @throws {Error} If the user is the project owner.
   */
  public deleteUserFromProject(user: ProjectUser) {
    this._dialog
      .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
        width: '360px',
        data: { dynamicContent: 'Benutzer entfernen' },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(takeAction => {
        if (!takeAction) return;
        if (user.authUserId === this._projectService.project$.value?.projectOwner) {
          this._snackbarService.showErrorMessage('Der Projekteigentümer kann nicht gelöscht werden.');
          throw new Error('deleteUserFromProject: Project owner cannot be deleted from Project');
        }
        lastValueFrom(this._projectService.deleteUserFromProject(this._projectService.project$.value!.id, user)).catch(() => {
          this._snackbarService.showErrorMessage('Benutzer konnte nicht entfernt werden.');
          throw new Error('deleteUserFromProject: Error while deleting project reference from user');
        });
        lastValueFrom(this._projectService.removeProjectUserAuthUserId(this._projectService.project$.value!.id, user.id)).catch(() => {
          this._snackbarService.showErrorMessage('Beim Entfernen des Benutzers ist ein Fehler aufgetreten.');
          throw new Error('deleteUserFromProject: Error while deleting authUserId from project user');
        });
        lastValueFrom(this._roleService.removeProjectRoleAssignmentUserRole(this._projectService.project$.value!.id, user.id)).catch(() => {
          this._snackbarService.showErrorMessage('Beim Entfernen des Benutzerprojekt Rollenzuweisung ist ein Fehler aufgetreten.');
          throw new Error('deleteUserFromProject: Error while deleting project user role assignment');
        });
      });
  }

  /**
   * Toggles the status of a project user between 'active' and 'inactive'.
   * Displays a confirmation dialog before changing the status.
   * Shows an error message if the user is the project owner or if the status update fails.
   *
   * @param {ProjectUser} user - The user whose status is to be toggled.
   * @throws {Error} If the user is the project owner.
   */
  public toggleUserStatus(user: ProjectUser) {
    const userStatus: UserStatus = user.userStatus || 'active';
    const toggledStatus: UserStatus = userStatus === 'active' ? 'inactive' : 'active';
    this._dialog
      .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
        width: '360px',
        data: {
          dynamicContent: userStatus === 'active' ? 'Benutzer deaktivieren' : 'Benutzer aktivieren',
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(takeAction => {
        if (!takeAction) return;
        if (user.authUserId === this._projectService.project$.value?.projectOwner) {
          this._snackbarService.showErrorMessage('Der Projekteigentümer kann nicht deaktiviert werden.');
          throw new Error('toggleUserStatus: Project owner cannot be deleted');
        }

        lastValueFrom(
          this._projectService.updateProjectUser(this._projectService.project$.value!.id, user.id, { userStatus: toggledStatus })
        ).catch(() => {
          this._snackbarService.showErrorMessage('Benutzer konnte nicht deaktiviert werden.');
          throw new Error('toggleUserStatus: Error while updating user status');
        });
      });
  }

  /**
   * Opens the Stripe customer portal for the current project.
   * Retrieves the Stripe information and creates a customer portal session.
   * Opens the customer portal in a new browser tab.
   */
  public openCustomerPortal() {
    this.isCustomerPortalLoading$.next(true);
    this._projectService
      .getStripeInfo()
      .pipe(
        switchMap((info: StripeInfo | null) => {
          if (info) {
            return this._stripeService.createCustomerPortalSession(info.customerId, this._projectService.project$.value!.id);
          } else {
            return EMPTY;
          }
        }),
        tap((session: StripeCustomerPortalSession | null) => {
          window.open(session?.url, '_self');
        }),
        catchError(err => {
          this.isCustomerPortalLoading$.next(false);
          return err;
        })
      )
      .subscribe(() => {
        this.isCustomerPortalLoading$.next(false);
      });
  }

  /**
   * Displays an error message when inviting a user fails.
   *
   * @private
   * @param {unknown} error - The error that occurred during the invitation process.
   */
  private showInviteUserErrorMessage(error: unknown) {
    const errorString: string = retrieveErrorMessageFromUnknownError(error);
    if (errorString.includes('User exists')) {
      this._snackbarService.showErrorMessage('Der Benutzer existiert bereits.');
      return;
    }

    if (errorString.includes('No Users found')) {
      this._snackbarService.showErrorMessage('Der Benutzer wurde nicht gefunden.');
      return;
    }

    this._snackbarService.showErrorMessage('Einladung konnte nicht versendet werden.');
  }
}
