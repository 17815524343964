import { Component, OnInit } from '@angular/core';
import { AuditSummaryComponent } from '../audit-summary/audit-summary.component';
import { TaskService } from '../../../../core/services/task.service';
import { ProjectService } from '../../../../core/services/project.service';
import { catchError, switchMap, takeUntil, tap, throwError } from 'rxjs';
import { TaskData } from '@eeule/eeule-shared';
import {
  CompareValues,
} from '../../../../core/components/compare-values-visualization/compare-values-visualization.component';
import { TaskStatusEnum } from '../../../../enums/TaskStatus.enum';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { BaseComponent } from '../../../../core/components/base/base.component';
import { SnackbarService } from '../../../../core/services/snackbar.service';
import { AuditTaskDataChartsComponent } from '../audit-task-data-charts/audit-task-data-charts.component';

@Component({
  selector: 'eule-audit-start-dashboard',
  standalone: true,
  imports: [
    AuditSummaryComponent,
    MatProgressSpinner,
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatCardContent,
    AuditTaskDataChartsComponent,
  ],
  templateUrl: './audit-start-dashboard.component.html',
  styleUrl: './audit-start-dashboard.component.scss',
})
export class AuditStartDashboardComponent extends BaseComponent implements OnInit {

  taskDataIsLoading: boolean = false;

  tasks: TaskData[] | null = null;

  tasksCountVsDone: CompareValues | null = null;

  constructor(
    private _taskService: TaskService,
    private _projectService: ProjectService,
    private _snackbarService: SnackbarService,
  ) {
    super();
  }

  ngOnInit() {
    this.initTaskData();
  }

  /**
   * Initializes the task data by subscribing to the project observable and fetching tasks.
   * If the project is null, it returns an empty array.
   * If an error occurs during the task fetching, it shows an error message using the SnackbarService.
   * The tasks and tasksCountVsDone properties are updated based on the fetched tasks.
   */
  private initTaskData(): void {
    this.taskDataIsLoading = true;
    this._projectService.project$.pipe(
      switchMap(project => {
        if (!project) {
          return [];
        }
        return this._taskService.getAllProjectTasks(project.id);
      }),
      catchError((err) => {
        this._snackbarService.showErrorMessage('Fehler beim Laden der Aufgaben');
        return throwError(() => err);
      }),
      tap(() => this.taskDataIsLoading = false),
      takeUntil(this.stop$),
    ).subscribe(tasks => {
      this.tasks = tasks;
      this.tasksCountVsDone = {
        leftValue: {
          value: tasks.length,
          subText: 'Aufgaben',
        },
        rightValue: {
          value: tasks.filter(task => task.status === 'DONE' as TaskStatusEnum).length,
          subText: 'Fertig',
        },
      };
    });
  }

}
