import { Component, Input } from '@angular/core';
import { CustomTooltipDirective } from '../../directives/custom-tooltip.directive';

export type LeafIconData = {
  value: string, tooltip: string
}

@Component({
  selector: 'eule-leaf-icon-list',
  standalone: true,
  imports: [
    CustomTooltipDirective,
  ],
  templateUrl: './leaf-icon-list.component.html',
  styleUrl: './leaf-icon-list.component.scss'
})
export class LeafIconListComponent {
  /** Leaf icons to display */
  @Input({ required: true }) leafIcons!: LeafIconData[];

  /** Number of visible leafs */
  @Input() visibleLeafs: number = 0;

  /**
   * Generates a tooltip containing all leaf icon tooltips.
   *
   * This getter method checks if there are any leaf icons with tooltips. If there are,
   * it generates an HTML string for a tooltip that contains a list of all the tooltips.
   *
   * @returns {string | null} The HTML string for the tooltip, or null if no tooltips are available.
   */
  get allItemsTooltip(): string | null {
    if (!this.leafIcons ||this.leafIcons.filter(o => o.tooltip).length === 0) {
      return null;
    }

    return `<ul class="no-margin" style="padding: 0 .5rem 0 1.5rem">` + this.leafIcons.map(leaf => {
      return `<li>${leaf.tooltip}</li>`
    }).join('') + '</ul>';
  }

}
